import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { fadeUp, vshrink } from '../../utils/animations';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MuchetCard, ProfileSummary } from 'muchet-models';
import User = firebase.User;

interface ViewCard {
    id: string;
    profileSummary: ProfileSummary;
}

@Component({
    selector: 'app-portal-view',
    templateUrl: './portal-view.component.html',
    styleUrls: ['./portal-view.component.scss'],
    animations: [vshrink(), fadeUp()],
})
export class PortalViewComponent implements OnInit, OnDestroy {
    user: User;
    cards: ViewCard[];
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private auth: AngularFireAuth,
        private router: Router,
        private firestore: AngularFirestore
    ) {}

    async ngOnInit() {
        await this.auth.currentUser.then((user) => (this.user = user));
        this.firestore
            .collection('cards', (ref) =>
                ref.where('userId', '==', this.user.uid)
            )
            .valueChanges({ idField: 'id' })
            .pipe(
                map((cards) => cards.map(this.buildViewCard)),
                takeUntil(this.destroy$)
            )
            .subscribe((cards) => (this.cards = cards));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    async logout() {
        await this.auth.signOut();
        await this.router.navigate(['login']);
    }

    trackCardBy(index, item: ViewCard) {
        return item.id;
    }

    buildViewCard = (card: MuchetCard & { id: string }): ViewCard => {
        return {
            id: card.id,
            profileSummary: card.profileSummary,
        };
    };

    async viewProfile(cardId: string) {
        await this.router.navigate(['p', cardId]);
    }

    async editProfile(cardId: string) {
        await this.router.navigate(['p', cardId, 'edit']);
    }

    async purchaseCard() {
        await this.router.navigate(['checkout', 'pre']);
    }
}
