import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { ModalService } from '../../services/modal.service';
import {
    AvatarCropperModalComponent,
    AvatarCropperModalInput,
    AvatarCropperModalOutput,
} from './avatar-cropper-modal/avatar-cropper-modal.component';
import { fileToBase64 } from '../../utils/file-utils';

@Component({
    selector: 'app-avatar-uploader',
    templateUrl: './avatar-uploader.component.html',
    styleUrls: ['./avatar-uploader.component.scss'],
})
export class AvatarUploaderComponent {
    _shakeUploadButton = false;

    @Input('imageData') set setImageData(data: Blob) {
        this.imageData = data;
        if (data)
            fileToBase64(this.imageData).then((url) => (this.imageUrl = url));
        else this.imageUrl = null;
    }

    imageData: Blob;
    @Output()
    imageDataChange: EventEmitter<Blob> = new EventEmitter<Blob>();
    @ViewChild('fileSelector') fileInput: ElementRef;
    imageUrl: string;
    fileSizeLimitMb = 10;

    constructor(private modalService: ModalService) {}

    shakeUploadButton() {
        this._shakeUploadButton = false;
        requestAnimationFrame(() => (this._shakeUploadButton = true));
    }

    async fileChangeEvent(files: FileList) {
        if (!files.length) return;
        const file = files[0];
        if (file.size >= 1024 * 1024 * this.fileSizeLimitMb) {
            this.fileInput.nativeElement.value = '';
            this.shakeUploadButton();
            return;
        }
        const res = await this.modalService
            .showModal<
                AvatarCropperModalComponent,
                AvatarCropperModalInput,
                AvatarCropperModalOutput
            >(AvatarCropperModalComponent, {
                image: file,
            })
            .toPromise();
        if (!res) return;
        this.setImageData = res.image;
        this.imageDataChange.emit(this.imageData);
    }

    removeAvatar() {
        this.imageData = null;
        this.imageDataChange.emit(null);
    }
}
