<div class="footer">
    <div class="footer-top">
        <div class="footer-info">
            <div class="title">Muchet</div>
            <div class="content">
                We're a company founded by three students who want to
                revolutionize the world.
            </div>
        </div>
        <div class="footer-list">
            <div class="title">Info</div>
            <a class="footer-item" routerLink="/tos">Terms of Service</a>
            <a class="footer-item" routerLink="/shipping_returns"
                >Return & Shipping Policy</a
            >
            <a class="footer-item" routerLink="/privpol">Privacy</a>
            <a class="footer-item" routerLink="/compatibility"
                >Compatibility List</a
            >
        </div>
        <div class="footer-list">
            <div class="title">Join our partner program</div>
            <a class="footer-item" routerLink="affiliate"
                >Affiliate Marketing</a
            >
            <a class="footer-item" routerLink="requirements">Requirements</a>
            <a class="footer-item" routerLink="license">License</a>
            <a class="footer-item" routerLink="updates">Updates</a>
            <a class="footer-item" routerLink="contact">Contact</a>
        </div>
        <div class="footer-list">
            <div class="title">Connect</div>
            <a class="footer-item" routerLink="twitter">Twitter</a>
            <a class="footer-item" routerLink="facebook">Facebook</a>
            <a class="footer-item" routerLink="instagram">Instagram</a>
            <a class="footer-item" routerLink="medium">Medium</a>
        </div>
    </div>
    <div class="footer-bottom">
        Muchet © 2021 all rights reserved.<br />KVK: 83656626
    </div>
</div>
