<input
    type="file"
    class="file-selector"
    (change)="fileChangeEvent(fileSelector.files)"
    accept="image/x-png,image/jpeg"
    #fileSelector
/>
<div class="avatar-view">
    <div class="avatar-container">
        <img [srcset]="imageUrl" *ngIf="imageUrl" />
        <img src="/assets/img/default_avatar.png" *ngIf="!imageUrl" />
    </div>
    <div class="action-container">
        <button
            class="btn btn-secondary"
            [disabled]="!imageData"
            (click)="removeAvatar()"
        >
            Remove Avatar
        </button>
        <button
            class="btn btn-secondary animate__animated"
            [class.animate__headShake]="_shakeUploadButton"
            (click)="fileSelector.click()"
        >
            Select New
        </button>
        <span>(Max. {{ fileSizeLimitMb }} Mb)</span>
    </div>
</div>
