import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';

@Component({
    selector: 'app-about-view',
    templateUrl: './about-view.component.html',
    styleUrls: ['./about-view.component.scss'],
})
export class AboutViewComponent implements OnInit, OnDestroy {
    constructor(private themeService: ThemeService) {}

    ngOnInit(): void {
        this.themeService.setMode('LIGHT', false);
    }

    ngOnDestroy(): void {
        this.themeService.setMode();
    }
}
