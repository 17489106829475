import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ThemeService } from "../../services/theme.service";

@Component({
    selector: 'app-landing-standard-layout',
    templateUrl: './landing-standard-layout.component.html',
    styleUrls: ['./landing-standard-layout.component.scss'],
})
export class LandingStandardLayoutComponent implements OnInit, OnDestroy {
    @Input() title: string;

    constructor(private themeService: ThemeService) {}

    ngOnInit(): void {
        this.themeService.setMode('LIGHT', false);
    }

    ngOnDestroy(): void {
        this.themeService.setMode();
    }
}
