import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeUp } from '../../utils/animations';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import {
    map,
    switchMap,
    take,
    takeUntil,
    withLatestFrom,
} from 'rxjs/operators';
import { MuchetCard, Order, OrderStatus, Product } from 'muchet-models';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { ContextMenuService } from '../../services/context-menu.service';
import { ContextMenuContent } from '../../components/generic-context-menu/generic-context-menu.component';
import { ORDER_STATUS_MAP } from '../../models/order-status-map.model';

interface ViewOrder {
    id: string;
    productName: string;
    creationDate: string;
    lastUpdated: string;
    status: OrderStatus;
    statusLabel: string;
    statusClass: string;
    price: string;
    address: string;
    email: string;
    profileUrl: string;
}

@Component({
    selector: 'app-order-view',
    templateUrl: './order-view.component.html',
    styleUrls: ['./order-view.component.scss'],
    animations: [fadeUp()],
})
export class OrderViewComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    order: ViewOrder;

    constructor(
        private firestore: AngularFirestore,
        private route: ActivatedRoute,
        private router: Router,
        private contextMenuService: ContextMenuService
    ) {}

    async fetchProfileUrl(order: Order): Promise<string> {
        // Use cached version if possible
        if (
            this.order &&
            this.order?.id === order.id &&
            this.order.status === 'PAYMENT_SUCCEEDED' &&
            this.order.profileUrl
        ) {
            return this.order.profileUrl;
        }

        const card: MuchetCard = await this.firestore
            .collection<MuchetCard>('cards', (ref) =>
                ref.where('orderId', '==', order.id).limit(1)
            )
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1),
                map((cards: MuchetCard[]) => (cards.length ? cards[0] : null))
            )
            .toPromise();

        return `${environment.baseUrl}/p/${card.id}`;
    }

    ngOnInit(): void {
        this.route.params
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (params) => {
                if (!params.orderId) {
                    await this.router.navigate(['portal']);
                    return;
                }
                const orderId = params.orderId;
                this.firestore
                    .collection<Order>('orders')
                    .doc(orderId)
                    .valueChanges({ idField: 'id' })
                    .pipe(
                        takeUntil(this.destroy$),
                        switchMap((order: Order) =>
                            this.firestore
                                .collection<Product>('products')
                                .doc(order.productId)
                                .valueChanges({ idField: 'id' })
                                .pipe(
                                    map(
                                        (product) =>
                                            [order, product] as [Order, Product]
                                    )
                                )
                        ),
                        switchMap(async ([order, product]) => {
                            const profileUrl = await this.fetchProfileUrl(
                                order
                            );
                            return this.buildViewOrder(
                                order,
                                product,
                                profileUrl
                            );
                        })
                    )
                    .subscribe((order) => (this.order = order));
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    buildViewOrder = (
        order: Order,
        product: Product,
        profileUrl: string
    ): ViewOrder => {
        /* eslint-enable @typescript-eslint/naming-convention */
        return {
            id: order.id,
            productName: product.name,
            creationDate: moment.unix(order.creation).format('DD/MM/YY HH:mm'),
            lastUpdated: moment
                .unix(order.lastUpdated)
                .format('DD/MM/YY HH:mm'),
            status: order.status,
            statusLabel: ORDER_STATUS_MAP[order.status].label,
            statusClass: ORDER_STATUS_MAP[order.status].className,
            price: order.amount.toFixed(2) + ' ' + order.currency.toUpperCase(),
            address: order.address
                ? `${order.address.name}
${order.address.address.join('\n')}
${order.address.postalCode} ${order.address.city}
${[order.address.state, order.address.country].filter((s) => !!s).join(' ')}`
                : 'Not provided',
            profileUrl,
            email: order.customerEmail,
        };
    };

    onClickStatus($event: MouseEvent) {
        this.contextMenuService.openMenu(
            {
                items: Object.entries(ORDER_STATUS_MAP).map((status) => ({
                    type: 'ITEM',
                    text: status[1].label,
                    onClick: () =>
                        this.setOrderStatus(
                            this.order.id,
                            status[0] as OrderStatus
                        ),
                })),
            },
            $event
        );
    }

    async setOrderStatus(orderId: string, status: OrderStatus) {
        await this.firestore.collection<Order>('orders').doc(orderId).update({
            status,
        });
    }
}
