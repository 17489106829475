import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginViewComponent } from './views/login-view/login-view.component';
import { trigger } from '@angular/animations';
import {
    routeFade,
    routeFadeUpPop,
    routeFadeUpPush,
} from './utils/route-transitions';
import { PortalViewComponent } from './views/portal-view/portal-view.component';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { of, pipe } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import firebase from 'firebase';
import { PreCheckoutViewComponent } from './views/pre-checkout-view/pre-checkout-view.component';
import { PostCheckoutViewComponent } from './views/post-checkout-view/post-checkout-view.component';
import { ProfileViewComponent } from './views/profile-view/profile-view.component';
import { OrdersViewComponent } from './views/orders-view/orders-view.component';
import { ProfileEditViewComponent } from './views/profile-edit-view/profile-edit-view.component';
import { OrderViewComponent } from './views/order-view/order-view.component';
import User = firebase.User;
import { HomeViewComponent } from './views/home-view/home-view.component';
import { AboutViewComponent } from './views/about-view/about-view.component';
import { MarkdownViewComponent } from './views/markdown-view/markdown-view.component';
import mdCompat from 'raw-loader!../assets/md/compat.md';
import mdPrivPol from 'raw-loader!../assets/md/privpol.md';
import mdShippingReturns from 'raw-loader!../assets/md/shipping_returns.md';
import mdTos from 'raw-loader!../assets/md/tos.md';

const redirectLoggedInToPortal = () =>
    pipe(
        map((user: User) => !!user && user.emailVerified),
        map((authenticated) => (authenticated && ['portal']) || true)
    );
const redirectUnauthorizedToLogin = () =>
    pipe(
        map((user: User) => !!user && user.emailVerified),
        map((authenticated) => authenticated || ['login'])
    );
const redirectNonAdministratorToPortal = () =>
    pipe(
        switchMap((user: User) => (user ? user.getIdTokenResult() : of(null))),
        map(
            (idTokenResult) =>
                idTokenResult && idTokenResult.claims.role === 'ADMIN'
        ),
        map((authorized) => authorized || ['portal'])
    );
const routes: Routes = [
    {
        path: 'home',
        component: HomeViewComponent,
        canActivate: [AngularFireAuthGuard],
        data: { animation: 'home', authGuardPipe: redirectLoggedInToPortal },
    },
    {
        path: 'compatibility',
        component: MarkdownViewComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
            animation: 'mdinfo',
            authGuardPipe: redirectLoggedInToPortal,
            title: 'Compatibility',
            markdown: mdCompat,
        },
    },
    {
        path: 'privpol',
        component: MarkdownViewComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
            animation: 'mdinfo',
            authGuardPipe: redirectLoggedInToPortal,
            title: 'Privacy Policy',
            markdown: mdPrivPol,
        },
    },
    {
        path: 'shipping_returns',
        component: MarkdownViewComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
            animation: 'mdinfo',
            authGuardPipe: redirectLoggedInToPortal,
            title: 'Shipping & Returns',
            markdown: mdShippingReturns,
        },
    },
    {
        path: 'tos',
        component: MarkdownViewComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
            animation: 'mdinfo',
            authGuardPipe: redirectLoggedInToPortal,
            title: 'Terms & Conditions',
            markdown: mdTos,
        },
    },
    {
        path: 'about',
        component: AboutViewComponent,
        canActivate: [AngularFireAuthGuard],
        data: { animation: 'about', authGuardPipe: redirectLoggedInToPortal },
    },
    {
        path: 'login',
        component: LoginViewComponent,
        canActivate: [AngularFireAuthGuard],
        data: { animation: 'login', authGuardPipe: redirectLoggedInToPortal },
    },
    {
        path: 'portal',
        component: PortalViewComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
            animation: 'portal',
            authGuardPipe: redirectUnauthorizedToLogin,
        },
    },
    {
        path: 'orders',
        component: OrdersViewComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
            animation: 'orders',
            authGuardPipe: redirectUnauthorizedToLogin,
        },
    },
    {
        path: 'order/:orderId',
        component: OrderViewComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
            animation: 'order',
            authGuardPipe: redirectNonAdministratorToPortal,
        },
    },
    {
        path: 'checkout/pre',
        component: PreCheckoutViewComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
            animation: 'checkout-pre',
            authGuardPipe: redirectUnauthorizedToLogin,
        },
    },
    {
        path: 'checkout/post',
        component: PostCheckoutViewComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
            animation: 'checkout-post',
            authGuardPipe: redirectUnauthorizedToLogin,
        },
    },
    {
        path: 'p/:cardId',
        component: ProfileViewComponent,
        data: {
            animation: 'profile',
        },
    },
    {
        path: 'p/:cardId/edit',
        component: ProfileEditViewComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
            animation: 'profile-edit',
            authGuardPipe: redirectUnauthorizedToLogin,
        },
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
    },
    {
        path: '**',
        redirectTo: 'home',
    },
];

export const routeAnimations = trigger('routeAnimations', [
    routeFadeUpPush('login', '*'),
    routeFadeUpPop('*', 'login'),

    routeFadeUpPush('portal', 'profile-edit'),
    routeFadeUpPop('profile-edit', 'portal'),

    routeFadeUpPush('portal', 'profile'),
    routeFadeUpPop('profile', 'portal'),

    routeFadeUpPush('profile', 'profile-edit'),
    routeFadeUpPop('profile-edit', 'profile'),

    routeFade('*', '*'),
]);

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
