import { Component, OnDestroy, OnInit } from '@angular/core';
import { CardProfile } from 'muchet-models';
import {
    AngularFirestore,
    AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import {
    catchError,
    debounceTime,
    filter,
    switchMap,
    takeUntil,
} from 'rxjs/operators';
import { BehaviorSubject, of, Subject } from 'rxjs';
import firebase from 'firebase';
import { isEqual } from 'lodash';
import { fadeUp, hshrink, vshrink } from '../../utils/animations';
import { classToPlain } from 'class-transformer';
import { validateSync, ValidationError } from 'class-validator';
import { AngularFireAuth } from '@angular/fire/auth';
import { ModalService } from '../../services/modal.service';
import {
    ConfirmationModalComponent,
    ConfirmationModalInput,
} from '../../components/confirmation-modal/confirmation-modal.component';
import { minPromiseDuration } from '../../utils/promise-utils';
import {
    AngularFireStorage,
    AngularFireStorageReference,
} from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import {
    getProfileAvatarImagePath,
    getProfileHeaderImagePath,
} from '../../utils/storage-paths';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

const getEmptyProfile = (fullName: string, userId: string): CardProfile =>
    Object.assign(new CardProfile(), {
        fullName,
        userId,
        email: {},
        telephone: {},
        socials: {},
    });

@Component({
    selector: 'app-profile-edit-view',
    templateUrl: './profile-edit-view.component.html',
    styleUrls: ['./profile-edit-view.component.scss'],
    animations: [fadeUp(), vshrink(), hshrink()],
})
export class ProfileEditViewComponent implements OnInit, OnDestroy {
    cardId: string;
    user: firebase.User;
    profile: CardProfile;
    destroy$: Subject<void> = new Subject<void>();
    profileDocRef: AngularFirestoreDocument<CardProfile>;
    profileDoc: DocumentSnapshot<CardProfile>;
    avatarImgRef: AngularFireStorageReference;
    headerImgRef: AngularFireStorageReference;
    mode: 'CREATE' | 'EDIT';
    validationErrors: BehaviorSubject<Array<ValidationError>> =
        new BehaviorSubject<Array<ValidationError>>([]);
    fieldsWithValidationErrors: string[] = [];
    saveState: 'IDLE' | 'SAVING' | 'ERROR' | 'SUCCESS' = 'IDLE';
    headerImageData: Blob;
    headerImageChanged = false;
    avatarImageData: Blob;
    avatarImageChanged = false;

    constructor(
        private route: ActivatedRoute,
        private firestore: AngularFirestore,
        private router: Router,
        private auth: AngularFireAuth,
        private modalService: ModalService,
        private storage: AngularFireStorage,
        private http: HttpClient
    ) {}

    async ngOnInit() {
        this.user = await this.auth.currentUser;
        if (!this.user) {
            await this.router.navigate(['portal']);
            return;
        }
        // Process route param to fetch existing profile
        this.route.params
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (params) => {
                if (!params.cardId) {
                    await this.router.navigate(['portal']);
                    return;
                }
                await this.loadProfileData(this.user, params.cardId);
            });
        // Process validation errors to get fields that do not pass validation
        this.validationErrors
            .pipe(debounceTime(300), takeUntil(this.destroy$))
            .subscribe((errors) => {
                this.processValidationErrors(errors);
            });
    }

    async processValidationErrors(errors: ValidationError[]) {
        const getFieldsWithValidationErrors = (
            _errors: ValidationError[],
            prefix = null
        ): string[] => {
            const fields = [];
            for (const error of _errors) {
                if (error.children?.length) {
                    fields.push(
                        ...getFieldsWithValidationErrors(
                            error.children,
                            [prefix, error.property]
                                .filter((p) => !!p)
                                .join('.')
                        )
                    );
                } else {
                    fields.push(
                        [prefix, error.property].filter((p) => !!p).join('.')
                    );
                }
            }
            return fields;
        };
        this.fieldsWithValidationErrors.splice(
            0,
            this.fieldsWithValidationErrors.length,
            ...getFieldsWithValidationErrors(errors)
        );
    }

    async loadProfileData(user: firebase.User, cardId: string) {
        this.cardId = cardId;
        this.mode = null;
        this.avatarImageData = null;
        this.headerImageData = null;
        this.avatarImageChanged = false;
        this.headerImageChanged = false;
        // Load profile data
        this.profileDocRef = this.firestore
            .collection('cards')
            .doc(this.cardId)
            .collection('profiles')
            .doc<CardProfile>('profile');
        this.profileDoc = await this.profileDocRef.get().toPromise();
        if (this.profileDoc.exists) {
            this.profile = Object.assign(new CardProfile(), this.profileDoc.data());
            this.mode = 'EDIT';
        } else {
            this.mode = 'CREATE';
            this.profile = getEmptyProfile(user.displayName, user.uid);
            this.profile.email.personalAddress = user.email + '';
        }
        // Load avatar
        this.avatarImgRef = this.storage.ref(
            getProfileAvatarImagePath(user.uid, cardId)
        );
        this.avatarImgRef
            .getDownloadURL()
            .pipe(
                switchMap((url) =>
                    this.http.get(url, { responseType: 'blob' })
                ),
                catchError((err) => {
                    console.warn('Could not download avatar image.', err);
                    return of(null);
                }),
                filter((d) => !!d),
                takeUntil(this.destroy$)
            )
            .subscribe((data: Blob) => (this.avatarImageData = data));
        // Load header image
        this.headerImgRef = this.storage.ref(
            getProfileHeaderImagePath(user.uid, cardId)
        );
        this.headerImgRef
            .getDownloadURL()
            .pipe(
                switchMap((url) =>
                    this.http.get(url, { responseType: 'blob' })
                ),
                catchError((err) => {
                    console.warn('Could not download header image.', err);
                    return of(null);
                }),
                filter((d) => !!d),
                takeUntil(this.destroy$)
            )
            .subscribe((data: Blob) => (this.headerImageData = data));
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    get canSaveProfile(): boolean {
        const validationErrors = validateSync(this.profile);
        console.log(this.profile, validationErrors);
        this.validationErrors.next(validationErrors);
        if (validationErrors.length) {
            return false;
        }
        return (
            !isEqual(classToPlain(this.profile), this.profileDoc?.data()) ||
            this.avatarImageChanged ||
            this.headerImageChanged
        );
    }

    get canViewProfile(): boolean {
        return this.mode === 'EDIT';
    }

    async viewProfile() {
        if (!this.canViewProfile) return;
        await this.router.navigate(['p', this.cardId]);
    }

    async saveProfile() {
        if (!this.canSaveProfile || this.saveState !== 'IDLE') return;
        this.saveState = 'SAVING';
        try {
            await minPromiseDuration(
                (async () => {
                    // Upload images
                    await Promise.all([
                        // Upload avatar image
                        (async () => {
                            if (this.avatarImageChanged) {
                                if (this.avatarImageData) {
                                    this.profile.avatarPath =
                                        getProfileAvatarImagePath(
                                            this.user.uid,
                                            this.cardId
                                        );
                                    return this.avatarImgRef.put(
                                        this.avatarImageData,
                                        { customMetadata: { avatar: 'true' } }
                                    );
                                } else {
                                    this.profile.avatarPath = null;
                                    return this.avatarImgRef
                                        .delete()
                                        .toPromise();
                                }
                            }
                        })(),
                        // Upload header image
                        (async () => {
                            if (this.headerImageChanged) {
                                if (this.headerImageData) {
                                    this.profile.headerPath =
                                        getProfileHeaderImagePath(
                                            this.user.uid,
                                            this.cardId
                                        );
                                    return this.headerImgRef.put(
                                        this.headerImageData
                                    );
                                } else {
                                    this.profile.headerPath = null;
                                    return this.headerImgRef
                                        .delete()
                                        .toPromise();
                                }
                            }
                        })(),
                    ]);
                    // Update profile data
                    await this.profileDocRef.set(
                        classToPlain(this.profile) as any
                    );
                })(),
                400
            );
            this.saveState = 'SUCCESS';
            setTimeout(async () => {
                if (this.mode === 'CREATE')
                    await this.router.navigate(['portal']);
            }, 1500);
        } catch (e) {
            console.error(e);
            this.saveState = 'ERROR';
            this.modalService.showModal<
                ConfirmationModalComponent,
                ConfirmationModalInput
            >(ConfirmationModalComponent, {
                showCancel: false,
                confirmText: 'Ok',
                title: 'Could not save profile',
                message:
                    'An error occurred while trying to save your profile. Please check your connection or try again later.',
            });
            return;
        } finally {
            setTimeout(async () => {
                this.saveState = 'IDLE';
            }, 1500);
        }
    }

    onHeaderImageChange(blob: Blob) {
        if (blob === this.headerImageData) return;
        this.headerImageData = blob;
        this.headerImageChanged = true;
    }

    onAvatarImageChange(blob: Blob) {
        if (blob === this.avatarImageData) return;
        this.avatarImageData = blob;
        this.avatarImageChanged = true;
    }
}
