<div class="page-bar about-header">
    <app-landing-nav-bar></app-landing-nav-bar>
    <div class="page-container">
        <h1>About</h1>
    </div>
</div>
<div class="page-bar about-info">
    <div class="page-container">
        <h2>We are entrepreneurs who like to think a little different.</h2>
        <div class="grid">
            <div class="about-profile">
                <h3 class="about-profile-quote">
                    “There are over a million companies registered in the
                    Netherlands and most of them only have one employee,
                    <b>the owner</b>.”
                </h3>
                <span class="about-profile-content">
                    “One of the major reasons why we started this company is to
                    help small businesses and freelancers connect with potential
                    customers. Freelancers are constantly balancing their time
                    on working on their craft and connecting with new clients.
                    With Muchet, they can easily tap their contact details on
                    the client’s phone. No more need for carrying around dozens
                    of paper cards that get lost in the client’s car. Just one
                    tap on the client’s phone and it’s all there. This way you
                    can connect with more people faster and easier. Allowing you
                    to have more time networking.”
                </span>
                <div class="about-profile-img-container">
                    <img
                        class="about-profile-img"
                        src="/assets/img/about_floris.png"
                    />
                </div>
                <span class="about-profile-img-subtext">
                    Floris Dutman
                    <br />
                    (Managing Director)
                </span>
            </div>
            <div class="about-profile">
                <h3 class="about-profile-quote">
                    “It is more than just a business card. It’s your
                    <b>digital identity</b> in the palm of your hands.”
                </h3>
                <span class="about-profile-content">
                    “The digital world has tremendously affected the way we life
                    our lives today. Social media has allowed us to connect with
                    people all over the world. Muchet wants to be apart of this
                    trend by giving people the ability to centralize their
                    social media on one platform. With the Muchet Card you have
                    all your socials on one place so that people can easily add
                    you on Instagram, LinkedIn and Twitter within seconds. This
                    makes our card more than just a updated business card but
                    rather a digital profile card.”
                </span>
                <div class="about-profile-img-container">
                    <img
                        class="about-profile-img"
                        src="/assets/img/about_stijn.png"
                    />
                </div>
                <span class="about-profile-img-subtext">
                    Stijn van der Hoeven
                    <br />
                    (Operations Director)
                </span>
            </div>
        </div>
    </div>
</div>
<div class="page-bar join-team">
    <div class="page-container">
        <h3>Want to join the team?</h3>
        <span>
            We are always looking for talent.
            <br />
            E-mail us at info@muchet.com.
        </span>
    </div>
</div>
<div class="page-bar">
    <div class="page-container">
        <app-landing-footer></app-landing-footer>
    </div>
</div>
