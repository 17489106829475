<div class="page-bar layout-header">
    <app-landing-nav-bar></app-landing-nav-bar>
    <div class="page-container">
        <h1>{{ title }}</h1>
    </div>
</div>
<div class="page-bar layout-content">
    <div class="page-container">
        <ng-content></ng-content>
    </div>
</div>
<div class="page-bar">
    <div class="page-container">
        <app-landing-footer></app-landing-footer>
    </div>
</div>
