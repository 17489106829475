import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { ThemeService } from '../../services/theme.service';
import { fadeDown, hshrink } from '../../utils/animations';
import User = firebase.User;

export interface PortalNavBarItem {
    label: string;
    route: string[];
}

@Component({
    selector: 'app-portal-nav-bar',
    templateUrl: './portal-nav-bar.component.html',
    styleUrls: ['./portal-nav-bar.component.scss'],
    animations: [fadeDown('dropdown'), hshrink()],
})
export class PortalNavBarComponent
    implements OnInit {
    user: User;
    showAccountDropdown = false;
    showMenuDropdown = false;
    navBarItems: PortalNavBarItem[] = [
        { label: 'Home', route: ['/home'] },
        { label: 'Portal', route: ['/portal'] },
        { label: 'Orders', route: ['/orders'] },
        { label: 'Purchase', route: ['/checkout/pre'] },
    ];

    constructor(
        private auth: AngularFireAuth,
        private router: Router,
        public theme: ThemeService
    ) {}

    ngOnInit(): void {
        this.auth.currentUser.then((user) => (this.user = user));
    }

    clickAccount() {
        setTimeout(
            () => (this.showAccountDropdown = !this.showAccountDropdown)
        );
    }

    clickMenu() {
        setTimeout(() => (this.showMenuDropdown = !this.showMenuDropdown));
    }

    async logout() {
        await this.auth.signOut();
        await this.router.navigate(['login']);
    }

    abbreviateEmail(email: string) {
        const maxLength = 28;
        if (email.length <= maxLength) return email;
        const [prefix, domain] = email.split('@');
        email =
            prefix +
            '@...' +
            domain.slice(
                domain.length - Math.floor(maxLength / 2 - 2),
                domain.length
            );
        if (email.length <= maxLength) return email;
        email =
            prefix.slice(0, Math.floor(maxLength / 2 - 2)) +
            '@...' +
            domain.slice(
                domain.length - Math.floor(maxLength / 2 - 2),
                domain.length
            );
        return email;
    }
}
