<div class="modal-bg" @bg @triggerModal>
    <div class="modal modal-md" @modal>
        <div class="modal-header">
            <h1>Crop Avatar</h1>
        </div>
        <div class="modal-content ai-center">
            <div class="flex-column ai-stretch">
                <div class="cropping-container" #croppingContainer></div>
            </div>
        </div>
        <div class="modal-actions">
            <button class="button btn btn-secondary" (click)="onCancel()">
                Cancel
            </button>
            <button class="button btn btn-primary" (click)="onSave()">
                Save
            </button>
        </div>
    </div>
</div>
