import { Component, OnInit } from '@angular/core';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { CardProfile } from 'muchet-models';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import firebase from 'firebase';
import { fadeUp } from '../../utils/animations';
import { plainToClass } from 'class-transformer';

@Component({
    selector: 'app-profile-view',
    templateUrl: './profile-view.component.html',
    styleUrls: ['./profile-view.component.scss'],
    animations: [fadeUp()],
})
export class ProfileViewComponent implements OnInit {
    destroy$: Subject<void> = new Subject<void>();
    profile: CardProfile;
    hasProfile = true;
    user: firebase.User;
    cardId: string;

    constructor(
        private auth: AngularFireAuth,
        private route: ActivatedRoute,
        private router: Router,
        private firestore: AngularFirestore
    ) {}

    async ngOnInit() {
        this.auth.currentUser.then((user) => (this.user = user));
        // Process route param to fetch existing profile
        this.route.params
            .pipe(
                map((params) => params.cardId),
                filter((cardId) => !!cardId),
                tap((cardId: string) => (this.cardId = cardId)),
                distinctUntilChanged(),
                debounceTime(100),
                switchMap((cardId) => {
                    const doc = this.firestore
                        .collection('cards')
                        .doc(cardId)
                        .collection('profiles')
                        .doc<CardProfile>('profile');
                    return doc.valueChanges();
                }),
                tap((value) => (this.hasProfile = !!value)),
                takeUntil(this.destroy$)
            )
            .subscribe(
                (profile) =>
                    (this.profile = profile
                        ? plainToClass(CardProfile, profile)
                        : null)
            );
    }
}
