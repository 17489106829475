import { Component, OnInit } from '@angular/core';
import { CheckoutService } from '../../services/checkout.service';
import { ModalService } from '../../services/modal.service';
import {
    ConfirmationModalComponent,
    ConfirmationModalInput,
    ConfirmationModalOutput,
} from '../../components/confirmation-modal/confirmation-modal.component';
import { hshrink, noop, vshrink } from '../../utils/animations';
import { AngularFirestore } from '@angular/fire/firestore';
import { Product } from 'muchet-models';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-pre-checkout-view',
    templateUrl: './pre-checkout-view.component.html',
    styleUrls: ['./pre-checkout-view.component.scss'],
    animations: [hshrink(), vshrink()],
})
export class PreCheckoutViewComponent implements OnInit {
    checkingOutId: string;
    products: Observable<Product[]>;

    constructor(
        private checkoutService: CheckoutService,
        private modalService: ModalService,
        private firestore: AngularFirestore
    ) {}

    ngOnInit() {
        this.products = this.firestore
            .collection<Product>('products')
            .valueChanges({ idField: 'id' });
    }

    async checkout(productId: string) {
        if (this.checkingOutId) return;
        try {
            this.checkingOutId = productId;
            await this.checkoutService.checkout(productId);
        } catch (e) {
            this.checkingOutId = null;
            console.error(e);
            if (e.description) {
                this.modalService.showModal<
                    ConfirmationModalComponent,
                    ConfirmationModalInput,
                    ConfirmationModalOutput
                >(ConfirmationModalComponent, {
                    title: 'Could not check out.',
                    message: e.description,
                    confirmText: 'Ok',
                    showCancel: false,
                });
            }
        }
    }
}
