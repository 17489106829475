<app-portal-nav-bar></app-portal-nav-bar>
<div class="page-container">
    <div class="page-header">
        <div>
            <span *ngIf="mode === 'CREATE'">Create your profile</span>
            <span *ngIf="mode === 'EDIT'">Edit your profile</span>
        </div>
    </div>
    <div class="card no-padding" *ngIf="mode" @fadeUp>
        <div class="card-header">
            <div class="left xs-hidden">
                <img src="/assets/img/card.png" />
            </div>
            <div class="right">
                <button
                    class="btn btn-secondary"
                    [disabled]="!canViewProfile"
                    (click)="viewProfile()"
                >
                    <i class="material-icons">person</i>
                    <span>View Profile</span>
                </button>
                <button
                    class="btn btn-primary"
                    [class.success]="saveState === 'SUCCESS'"
                    [disabled]="!canSaveProfile"
                    (click)="saveProfile()"
                >
                    <span *ngIf="saveState === 'IDLE'" @hshrink>
                        <i class="material-icons">edit</i>
                        <span>Save Profile</span>
                    </span>
                    <app-spinner
                        size="SMALL"
                        *ngIf="saveState === 'SAVING'"
                        @hshrink
                    ></app-spinner>
                    <span *ngIf="saveState === 'SUCCESS'" @hshrink>
                        <i class="material-icons">done</i>
                    </span>
                </button>
            </div>
        </div>
        <div class="form-row flex-row ai-start jc-space-evenly">
            <div class="form-col">
                <div class="category">
                    <div class="category-header">Basic Information</div>
                    <div class="input-entry">
                        <div class="name">
                            <span>Full Name</span>
                            <span>(required)</span>
                        </div>
                        <div class="input-line">
                            <input
                                type="text"
                                class="input"
                                placeholder="Your Name"
                                [(ngModel)]="profile.fullName"
                            />
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes('fullName')
                            "
                            @vshrink
                        >
                            Your full name is required and cannot be longer than
                            128 characters.
                        </div>
                    </div>
                    <div class="input-entry">
                        <div class="name">
                            <span>Job Position</span>
                        </div>
                        <div class="input-line">
                            <input
                                type="text"
                                class="input"
                                placeholder="Your job position"
                                [(ngModel)]="profile.jobPosition"
                            />
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes(
                                    'jobPosition'
                                )
                            "
                            @vshrink
                        >
                            Your job position cannot be more than 64 characters.
                        </div>
                    </div>
                    <div class="input-entry">
                        <div class="name">
                            <span>Company</span>
                        </div>
                        <div class="input-line">
                            <input
                                type="text"
                                class="input"
                                placeholder="Company Name"
                                [(ngModel)]="profile.companyName"
                            />
                        </div>
                    </div>
                    <div
                        class="validation-error"
                        *ngIf="
                            fieldsWithValidationErrors.includes('jobPosition')
                        "
                        @vshrink
                    >
                        Your company name cannot be more than 64 characters.
                    </div>
                </div>
                <div class="category">
                    <div class="category-header">Email</div>
                    <div class="input-entry">
                        <div class="name">
                            <span>Personal Address</span>
                        </div>
                        <div class="input-line">
                            <input
                                type="text"
                                class="input"
                                placeholder="example@example.com"
                                [(ngModel)]="profile.email.personalAddress"
                            />
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes(
                                    'email.personalAddress'
                                )
                            "
                            @vshrink
                        >
                            This has to be a valid email address.
                        </div>
                    </div>
                    <div class="input-entry">
                        <div class="name">
                            <span>Work Address</span>
                        </div>
                        <div class="input-line">
                            <input
                                type="text"
                                class="input"
                                placeholder="example@example.com"
                                [(ngModel)]="profile.email.workAddress"
                            />
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes(
                                    'email.workAddress'
                                )
                            "
                            @vshrink
                        >
                            This has to be a valid email address.
                        </div>
                    </div>
                </div>
                <div class="category">
                    <div class="category-header">Telephone</div>
                    <div class="input-entry">
                        <div class="name">
                            <span>Mobile Number</span>
                        </div>
                        <div class="input-line">
                            <input
                                type="text"
                                class="input"
                                placeholder="Tel. #"
                                [(ngModel)]="profile.telephone.mobileNumber"
                            />
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes(
                                    'telephone.mobileNumber'
                                )
                            "
                            @vshrink
                        >
                            This number cannot be longer than 20 characters.
                        </div>
                    </div>
                    <div class="input-entry">
                        <div class="name">
                            <span>Home Number</span>
                        </div>
                        <div class="input-line">
                            <input
                                type="text"
                                class="input"
                                placeholder="Tel. #"
                                [(ngModel)]="profile.telephone.homeNumber"
                            />
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes(
                                    'telephone.homeNumber'
                                )
                            "
                            @vshrink
                        >
                            This number cannot be longer than 20 characters.
                        </div>
                    </div>
                    <div class="input-entry">
                        <div class="name">
                            <span>Work Number</span>
                        </div>
                        <div class="input-line">
                            <input
                                type="text"
                                class="input"
                                placeholder="Tel. #"
                                [(ngModel)]="profile.telephone.workNumber"
                            />
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes(
                                    'telephone.workNumber'
                                )
                            "
                            @vshrink
                        >
                            This number cannot be longer than 20 characters.
                        </div>
                    </div>
                </div>
                <div class="category">
                    <div class="category-header">Website</div>
                    <div class="input-entry">
                        <div class="name">
                            <span>Website URL</span>
                        </div>
                        <div class="input-line">
                            <input
                                type="text"
                                class="input"
                                placeholder="https://example.com"
                                [(ngModel)]="profile.website"
                            />
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes('website')
                            "
                            @vshrink
                        >
                            This must be a valid web url.
                        </div>
                    </div>
                </div>
                <div class="category">
                    <div class="category-header">Socials</div>
                    <div class="input-entry">
                        <div class="name">
                            <span>Twitter</span>
                        </div>
                        <div class="input-line">
                            <span>@</span
                            ><input
                                type="text"
                                class="input"
                                placeholder="Twitter Handle"
                                [(ngModel)]="profile.socials.twitterHandle"
                            />
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes(
                                    'socials.twitterHandle'
                                )
                            "
                            @vshrink
                        >
                            This must be a valid Twitter handle.
                        </div>
                    </div>
                    <div class="input-entry">
                        <div class="name">
                            <span>LinkedIn</span>
                        </div>
                        <div class="input-line">
                            <span>@</span
                            ><input
                                type="text"
                                class="input"
                                placeholder="Profile ID"
                                [(ngModel)]="profile.socials.linkedInHandle"
                            />
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes(
                                    'socials.linkedInHandle'
                                )
                            "
                            @vshrink
                        >
                            This must be a valid LinkedIn handle.
                        </div>
                    </div>
                    <div class="input-entry">
                        <div class="name">
                            <span>Instagram</span>
                        </div>
                        <div class="input-line">
                            <span>@</span>
                            <input
                                type="text"
                                class="input"
                                placeholder="Instagram Handle"
                                [(ngModel)]="profile.socials.instagramHandle"
                            />
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes(
                                    'socials.instagramHandle'
                                )
                            "
                            @vshrink
                        >
                            This must be a valid Instagram handle.
                        </div>
                    </div>
                    <div class="input-entry">
                        <div class="name">
                            <span>Facebook</span>
                        </div>
                        <div class="input-line">
                            <span>@</span>
                            <input
                                type="text"
                                class="input"
                                placeholder="Facebook Handle"
                                [(ngModel)]="profile.socials.facebookHandle"
                            />
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes(
                                    'socials.facebookHandle'
                                )
                            "
                            @vshrink
                        >
                            This must be a valid Facebook handle.
                        </div>
                    </div>
                    <div class="input-entry">
                        <div class="name">
                            <span>TikTok</span>
                        </div>
                        <div class="input-line">
                            <span>@</span>
                            <input
                                type="text"
                                class="input"
                                placeholder="TikTok Handle"
                                [(ngModel)]="profile.socials.tikTokHandle"
                            />
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes(
                                    'socials.tikTokHandle'
                                )
                            "
                            @vshrink
                        >
                            This must be a valid TikTok handle.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-col">
                <div class="category">
                    <div class="category-header">Avatar</div>
                    <div class="input-entry">
                        <app-avatar-uploader
                            [imageData]="avatarImageData"
                            (imageDataChange)="onAvatarImageChange($event)"
                        ></app-avatar-uploader>
                    </div>
                </div>
                <div class="category">
                    <div class="category-header">Header Image</div>
                    <div class="input-entry">
                        <app-image-uploader
                            [imageData]="headerImageData"
                            (imageDataChange)="onHeaderImageChange($event)"
                        ></app-image-uploader>
                    </div>
                </div>
                <div class="category">
                    <div class="category-header">About Me</div>
                    <div class="input-entry">
                        <div class="input-line">
                            <textarea
                                rows="5"
                                class="input"
                                placeholder="Say something about yourself"
                                [(ngModel)]="profile.aboutText"
                            ></textarea>
                        </div>
                        <div
                            class="validation-error"
                            *ngIf="
                                fieldsWithValidationErrors.includes('aboutText')
                            "
                            @vshrink
                        >
                            Your 'about me' cannot be more than 280 characters.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
