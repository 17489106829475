<div class="dialog-container" *ngIf="mode === 'LOGIN'" @fadeUp>
    <div class="card dialog">
        <app-logo></app-logo>
        <span class="title">Log in</span>
        <form class="input-block" (ngSubmit)="onLoginClick()">
            <input
                class="input"
                type="text"
                name="email"
                placeholder="E-mail address"
                [(ngModel)]="email"
            />
            <input
                class="input"
                type="password"
                name="password"
                placeholder="Password"
                [(ngModel)]="password"
            />
            <input
                type="submit"
                style="visibility: hidden; position: absolute"
            />
            <div
                class="alert alert-error login-error"
                *ngIf="loginError"
                @vshrink
            >
                {{ loginError }}
            </div>
        </form>
        <button
            class="btn btn-primary"
            (click)="onLoginClick()"
            [disabled]="
                loginStatus === 'IN_PROGRESS' ||
                loginStatus === 'SUCCESS' ||
                (!email && !password)
            "
        >
            <div class="flex-row jc-center ai-center">
                <div *ngIf="loginStatus === 'IN_PROGRESS'" @hshrink>
                    <app-spinner [size]="'SMALL'"></app-spinner>
                </div>
                <div
                    *ngIf="loginStatus === 'IDLE' || loginStatus === 'ERROR'"
                    @hshrink
                    class="nowrap"
                >
                    Sign In
                </div>
                <div *ngIf="loginStatus === 'SUCCESS'" @hshrink>
                    <i class="material-icons">done</i>
                </div>
            </div>
        </button>
        <button
            class="btn btn-google-signin"
            (click)="onGoogleLoginClick()"
            [disabled]="
                googleLoginStatus === 'IN_PROGRESS' || googleLoginStatus === 'SUCCESS'
            "
        >
            <span class="icon-wrapper">
                <img
                    src="https://developers.google.com/identity/images/g-logo.png"
                />
            </span>
            <div class="flex-row jc-center ai-center text">
                <div *ngIf="googleLoginStatus === 'IN_PROGRESS'" @hshrink>
                    <app-spinner [size]="'SMALL'"></app-spinner>
                </div>
                <div
                    *ngIf="googleLoginStatus === 'IDLE' || googleLoginStatus === 'ERROR'"
                    @hshrink
                    class="nowrap"
                >
                    Sign in with Google
                </div>
                <div *ngIf="googleLoginStatus === 'SUCCESS'" @hshrink>
                    <i class="material-icons">done</i>
                </div>
            </div>
        </button>
        <button
            class="btn btn-secondary"
            (click)="setMode('REGISTER')"
            [disabled]="
                loginStatus === 'IN_PROGRESS' || loginStatus === 'SUCCESS'
            "
        >
            Create Account
        </button>
        <a (click)="onForgetPassword()">Forgot Password</a>
    </div>
</div>

<div class="dialog-container" *ngIf="mode === 'REGISTER'" @fadeUp>
    <div class="card dialog">
        <span class="title">Register Account</span>
        <form class="input-block" (ngSubmit)="onRegisterClick()">
            <input
                class="input"
                type="text"
                name="displayName"
                placeholder="Full Name"
                [(ngModel)]="displayName"
            />
            <input
                class="input"
                type="text"
                name="email"
                placeholder="E-mail address"
                [(ngModel)]="email"
            />
            <input
                class="input"
                type="password"
                name="password"
                placeholder="Password"
                [(ngModel)]="password"
            />
            <input
                class="input"
                type="password"
                name="password"
                placeholder="Password"
                [(ngModel)]="passwordConfirm"
            />
            <input
                type="submit"
                style="visibility: hidden; position: absolute"
            />
            <div
                class="alert alert-error login-error"
                *ngIf="registerError"
                @vshrink
            >
                {{ registerError }}
            </div>
        </form>
        <button
            class="btn btn-primary"
            (click)="onRegisterClick()"
            [disabled]="
                registerStatus === 'IN_PROGRESS' ||
                registerStatus === 'SUCCESS' ||
                !validRegistrationDetails
            "
        >
            <div class="flex-row jc-center ai-center">
                <div *ngIf="registerStatus === 'IN_PROGRESS'" @hshrink>
                    <app-spinner [size]="'SMALL'"></app-spinner>
                </div>
                <div
                    *ngIf="
                        registerStatus === 'IDLE' || registerStatus === 'ERROR'
                    "
                    @hshrink
                    class="nowrap"
                >
                    Register
                </div>
                <div *ngIf="registerStatus === 'SUCCESS'" @hshrink>
                    <i class="material-icons">done</i>
                </div>
            </div>
        </button>
        <button
            class="btn btn-secondary"
            (click)="setMode('LOGIN')"
            [disabled]="
                registerStatus === 'IN_PROGRESS' || registerStatus === 'SUCCESS'
            "
        >
            Use Existing Account
        </button>
    </div>
</div>

<div class="dialog-container" *ngIf="mode === 'VERIFY_EMAIL'" @fadeUp>
    <div class="card dialog">
        <span class="title">Verify Email</span>
        <br />
        <p style="text-align: center">
            One last thing! <br /><br />
            Before you can log in, you must verify your email address. You
            should receive an e-mail in the upcoming minutes. Click the link in
            the e-mail to verify your email address. After doing this you should
            be able to log in!
        </p>
        <button
            class="btn btn-secondary"
            (click)="setMode('LOGIN')"
            style="margin-top: 1em"
        >
            Alright!
        </button>
    </div>
</div>
