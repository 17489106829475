<div class="header-image" [style]="headerImageStyle">
    <button class="vcard-button" (click)="saveContact()">
        <span>
            <i class="material-icons">person_add</i>
            Save contact
        </span>
    </button>
    <div class="loading" [@loading] *ngIf="!headerImageStyle"></div>
</div>
<div class="profile-body" *ngIf="profile">
    <div class="profile-header">
        <div class="avatar">
            <img [srcset]="avatarImage" *ngIf="avatarImage" />
            <div class="loading" [@loading] *ngIf="!avatarImage">
                <app-spinner></app-spinner>
            </div>
        </div>
        <div class="text-container">
            <div class="name">{{ profile.fullName }}</div>
            <div class="tagline" *ngIf="tagLine" @vshrink>
                {{ tagLine }}
            </div>
        </div>
    </div>
    <div class="profile-grid">
        <div class="profile-value" *ngIf="profile.aboutText" @fadeUp>
            <div class="title">
                <i class="material-icons">account_box</i>
                <span>About me</span>
            </div>
            <div class="value" style="white-space: pre-line">
                {{ profile.aboutText }}
            </div>
        </div>
        <div class="profile-value" *ngIf="emailAddressCount" @fadeUp>
            <div class="title">
                <i class="material-icons">email</i>
                <span>E-mail</span>
            </div>
            <a
                class="key-value"
                href="mailto:{{ profile.email?.personalAddress }}"
                *ngIf="profile.email?.personalAddress"
                @fadeUp
            >
                <span>Personal</span>
                <span>
                    <span>{{
                        profile.email.personalAddress.split('@')[0]
                    }}</span
                    ><wbr />@<wbr /><span>{{
                        profile.email.personalAddress.split('@')[1]
                    }}</span>
                </span>
            </a>
            <a
                class="key-value"
                href="mailto:{{ profile.email?.workAddress }}"
                *ngIf="profile.email?.workAddress"
                @fadeUp
            >
                <span>Work</span>
                <span>
                    <span>{{ profile.email.workAddress.split('@')[0] }}</span
                    ><wbr />@<wbr /><span>{{
                        profile.email.workAddress.split('@')[1]
                    }}</span>
                </span>
            </a>
        </div>
        <div class="profile-value" *ngIf="phoneNumberCount" @fadeUp>
            <div class="title">
                <i class="material-icons">call</i>
                <span>Telephone</span>
            </div>
            <a
                class="key-value"
                href="tel:{{ profile.telephone?.workNumber }}"
                *ngIf="profile.telephone?.workNumber"
                @fadeUp
            >
                <span>Work</span>
                <span>{{ profile.telephone?.workNumber }}</span>
            </a>
            <a
                class="key-value"
                href="tel:{{ profile.telephone?.homeNumber }}"
                *ngIf="profile.telephone?.homeNumber"
                @fadeUp
            >
                <span>Home</span>
                <span>{{ profile.telephone?.homeNumber }}</span>
            </a>
            <a
                class="key-value"
                href="tel:{{ profile.telephone?.mobileNumber }}"
                *ngIf="profile.telephone?.mobileNumber"
                @fadeUp
            >
                <span>Mobile</span>
                <span>{{ profile.telephone?.mobileNumber }}</span>
            </a>
        </div>
        <div class="profile-value" *ngIf="profile.website" @fadeUp>
            <div class="title">
                <i class="material-icons">public</i>
                <span>Website</span>
            </div>
            <a class="value" [href]="websiteUrl">
                <span>{{ profile.website }}</span>
            </a>
        </div>
        <div
            class="profile-value"
            *ngIf="profile.socials?.linkedInHandle"
            @fadeUp
        >
            <div class="title">
                <fa-icon
                    [icon]="['fab', 'linkedin']"
                    style="color: #0077b5"
                ></fa-icon>
                <span>LinkedIn</span>
            </div>
            <a
                class="value"
                href="https://www.linkedin.com/in/{{
                    profile.socials?.linkedInHandle
                }}/"
            >
                <span>Profile Page</span>
            </a>
        </div>
        <div
            class="profile-value"
            *ngIf="profile.socials?.twitterHandle"
            @fadeUp
        >
            <div class="title">
                <fa-icon
                    [icon]="['fab', 'twitter']"
                    style="color: #55acee"
                ></fa-icon>
                <span>Twitter</span>
            </div>
            <a
                class="value"
                href="https://twitter.com/{{ profile.socials?.twitterHandle }}"
            >
                <span>@{{ profile.socials?.twitterHandle }}</span>
            </a>
        </div>
        <div
            class="profile-value"
            *ngIf="profile.socials?.instagramHandle"
            @fadeUp
        >
            <div class="title">
                <fa-icon
                    [icon]="['fab', 'instagram']"
                    style="color: #e4405f"
                ></fa-icon>
                <span>Instagram</span>
            </div>
            <a
                class="value"
                href="https://www.instagram.com/{{
                    profile.socials?.instagramHandle
                }}/"
            >
                <span>@{{ profile.socials?.instagramHandle }}</span>
            </a>
        </div>
        <div
            class="profile-value"
            *ngIf="profile.socials?.facebookHandle"
            @fadeUp
        >
            <div class="title">
                <fa-icon
                    [icon]="['fab', 'facebook']"
                    style="color: #4267b2"
                ></fa-icon>
                <span>Facebook</span>
            </div>
            <a
                class="value"
                href="https://www.facebook.com/{{
                    profile.socials?.facebookHandle
                }}/"
            >
                <span>@{{ profile.socials?.facebookHandle }}</span>
            </a>
        </div>
        <div
            class="profile-value"
            *ngIf="profile.socials?.tikTokHandle"
            @fadeUp
        >
            <div class="title">
                <fa-icon
                    [icon]="['fab', 'tiktok']"
                    style="color: #000000"
                ></fa-icon>
                <span>TikTok</span>
            </div>
            <a
                class="value"
                href="https://www.tiktok.com/@{{
                    profile.socials?.tikTokHandle
                }}/"
            >
                <span>@{{ profile.socials?.tikTokHandle }}</span>
            </a>
        </div>
        <div class="profile-value" @fadeUp>
            <div class="title">
                <i class="material-icons">qr_code</i>
                <span>Profile Page</span>
            </div>
            <div class="profile-qrcode">
                <qr-code
                    [value]="profileUrl"
                    [size]="512"
                    [canvas]="true"
                ></qr-code>
            </div>
        </div>
    </div>
</div>
