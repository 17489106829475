<div class="row-container">
    <div class="left">
        <div class="nav-item" (clickOutside)="showMenuDropdown = false">
            <button
                class="nav-item-content menu-button sm-hidden"
                (click)="clickMenu()"
            >
                <i class="material-icons">menu</i>
                <app-logo></app-logo>
            </button>
            <button class="nav-item-content menu-button xs-hidden">
                <app-logo></app-logo>
            </button>
            <div
                class="dropdown card sm-hidden dropdown-menu"
                *ngIf="showMenuDropdown"
                @dropdown
            >
                <div class="nav-items">
                    <app-portal-nav-bar-item
                        *ngFor="let item of navBarItems"
                        [inDropdown]="true"
                        [route]="item.route"
                    >
                        {{ item.label }}
                    </app-portal-nav-bar-item>
                </div>
            </div>
        </div>
        <div class="nav-items xs-hidden">
            <app-portal-nav-bar-item
                *ngFor="let item of navBarItems"
                [route]="item.route"
            >
                {{ item.label }}
            </app-portal-nav-bar-item>
        </div>
    </div>
    <div class="right">
        <div
            class="nav-item"
            *ngIf="user"
            (clickOutside)="showAccountDropdown = false"
        >
            <button class="nav-item-content account" (click)="clickAccount()">
                <span class="username">
                    <span class="sm-hidden">
                        {{ abbreviateEmail(user.email) }}
                    </span>
                    <span class="xs-hidden">{{ user.email }}</span>
                </span>
            </button>
            <div class="dropdown card" *ngIf="showAccountDropdown" @dropdown>
                <div class="dropdown-theme">
                    <button
                        [class.active]="theme.settings.mode === 'SYSTEM'"
                        (click)="theme.setMode('SYSTEM')"
                    >
                        <i class="material-icons">brightness_auto</i>
                    </button>
                    <button
                        [class.active]="theme.settings.mode === 'LIGHT'"
                        (click)="theme.setMode('LIGHT')"
                    >
                        <i class="material-icons">brightness_5</i>
                    </button>
                    <button
                        [class.active]="theme.settings.mode === 'DARK'"
                        (click)="theme.setMode('DARK')"
                    >
                        <i class="material-icons">nights_stay</i>
                    </button>
                </div>
                <div class="dropdown-username username">
                    <span>{{ user.displayName }}</span>
                </div>
                <div class="dropdown-item" (click)="logout()">
                    <i class="material-icons">exit_to_app</i>
                    <span>Log out</span>
                </div>
            </div>
        </div>
    </div>
</div>
