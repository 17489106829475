import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Order, Product } from 'muchet-models';
import { combineLatest, Observable, Subject } from 'rxjs';
import { vshrink } from '../../utils/animations';
import { map, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { ORDER_STATUS_MAP } from "../../models/order-status-map.model";

interface ViewOrder {
    id: string;
    productName: string;
    creationDate: string;
    lastUpdated: string;
    statusLabel: string;
    statusClass: string;
    price: string;
    address: string;
}

@Component({
    selector: 'app-orders-view',
    templateUrl: './orders-view.component.html',
    styleUrls: ['./orders-view.component.scss'],
    animations: [vshrink()],
})
export class OrdersViewComponent implements OnInit, OnDestroy {
    orders: Array<ViewOrder> = [];
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private firestore: AngularFirestore,
        private auth: AngularFireAuth
    ) {}

    async ngOnInit() {
        const user = await this.auth.currentUser;
        combineLatest([
            this.firestore
                .collection<Order>('orders', (ref) =>
                    ref
                        .where('userId', '==', user.uid)
                        .orderBy('creation', 'desc')
                )
                .valueChanges({ idField: 'id' }),
            this.firestore
                .collection<Product>('products')
                .valueChanges({ idField: 'id' }),
        ])
            .pipe(
                map(([orders, products]) =>
                    orders.map((order) => this.buildViewOrder(order, products))
                ),
                takeUntil(this.destroy$)
            )
            .subscribe((orders) => (this.orders = orders));
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    buildViewOrder = (order: Order, products: Product[]): ViewOrder => {
        /* eslint-enable @typescript-eslint/naming-convention */
        return {
            id: order.id,
            productName:
                products.find((p) => p.id === order.productId)?.name ??
                'Unknown Product',
            creationDate: moment.unix(order.creation).format('DD/MM/YY HH:mm'),
            lastUpdated: moment
                .unix(order.lastUpdated)
                .format('DD/MM/YY HH:mm'),
            statusLabel: ORDER_STATUS_MAP[order.status].label,
            statusClass: ORDER_STATUS_MAP[order.status].className,
            price: order.amount.toFixed(2) + ' ' + order.currency.toUpperCase(),
            address: order.address
                ? `${order.address.name}
${order.address.address.join('\n')}
${order.address.postalCode} ${order.address.city}
${[order.address.state, order.address.country].filter((s) => !!s).join(' ')}`
                : 'Not provided',
        };
    };
}
