import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-post-checkout-view',
    templateUrl: './post-checkout-view.component.html',
    styleUrls: ['./post-checkout-view.component.scss'],
})
export class PostCheckoutViewComponent implements OnInit {
    status: 'SUCCESS' | 'FAILURE';

    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            switch (params.status) {
                case 'success':
                    this.status = 'SUCCESS';
                    break;
                case 'failure':
                    this.status = 'FAILURE';
                    break;
                default:
                    this.router.navigate(['portal']);
                    break;
            }
        });
    }
}
