import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFireStorage } from '@angular/fire/storage';
import { ProfileComponent } from '../profile.component';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-profile-desktop',
    templateUrl: './profile-desktop.component.html',
    styleUrls: ['./profile-desktop.component.scss'],
})
export class ProfileDesktopComponent extends ProfileComponent {
    constructor(
        sanitizer: DomSanitizer,
        storage: AngularFireStorage,
        http: HttpClient
    ) {
        super(sanitizer, storage, http);
    }
}
