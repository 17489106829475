import { Injectable } from '@angular/core';
import { ThemeService } from './theme.service';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from "firebase";
import Auth = firebase.auth.Auth;

@Injectable({
    providedIn: 'root',
})
export class AppInitService {
    constructor(
        private themeService: ThemeService,
        public auth: AngularFireAuth
    ) {}

    async init(): Promise<void> {
        await this.themeService.init();
        await this.auth.setPersistence(Auth.Persistence.LOCAL);
    }
}
