import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-portal-nav-bar-item',
    templateUrl: './portal-nav-bar-item.component.html',
    styleUrls: ['./portal-nav-bar-item.component.scss'],
})
export class PortalNavBarItemComponent {
    @Input() route: string[];
    @Input() inDropdown = false;

    constructor() {}
}
