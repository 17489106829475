import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { fileToBase64 } from '../../utils/file-utils';

@Component({
    selector: 'app-image-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent {
    _shakeImagePreview = false;

    @Input('imageData') set setImageData(data: Blob) {
        this.imageData = data;
        if (data)
            fileToBase64(this.imageData).then((url) => (this.imageUrl = url));
        else this.imageUrl = null;
    }

    imageData: Blob;
    @Output()
    imageDataChange: EventEmitter<Blob> = new EventEmitter<Blob>();
    @ViewChild('fileSelector') fileInput: ElementRef;
    imageUrl: string;
    fileSizeLimitMb = 20;

    constructor() {}

    shakeImagePreview() {
        this._shakeImagePreview = false;
        requestAnimationFrame(() => (this._shakeImagePreview = true));
    }

    async fileChangeEvent(files: FileList) {
        if (!files.length) return;
        const file = files[0];
        if (file.size >= 1024 * 1024 * this.fileSizeLimitMb) {
            this.fileInput.nativeElement.value = '';
            this.shakeImagePreview();
            return;
        }
        this.setImageData = file;
        this.imageDataChange.emit(this.imageData);
    }

    removeAvatar() {
        this.imageData = null;
        this.imageDataChange.emit(null);
    }
}
