<app-portal-nav-bar></app-portal-nav-bar>
<div class="page-container">
    <div class="card" *ngIf="order" @fadeUp>
        <h1>Order ({{ order.id }})</h1>
        <div class="grid xs-1 sm-2">
            <div>
                <h2>Product</h2>
                <div>{{ order.productName }}</div>
            </div>
            <div>
                <h2>Status</h2>
                <div>
                    <div
                        class="order-status clickable"
                        [ngClass]="order.statusClass"
                        (click)="onClickStatus($event)"
                    >
                        {{ order.statusLabel }}
                    </div>
                </div>
            </div>
            <div>
                <h2>Price</h2>
                <div>{{ order.price }}</div>
            </div>
            <div>
                <h2>Shipping Address</h2>
                <div style="white-space: pre">{{ order.address }}</div>
            </div>
            <div>
                <h2>Creation Date</h2>
                <div>{{ order.creationDate }}</div>
            </div>
            <div>
                <h2>Last Updated</h2>
                <div>{{ order.lastUpdated }}</div>
            </div>
            <div>
                <h2>Profile URL</h2>
                <a [href]="order.profileUrl || '#'">{{
                    order.profileUrl || 'Undetermined'
                }}</a>
            </div>
            <div>
                <h2>Customer E-mail</h2>
                <div>{{ order.email }}</div>
            </div>
        </div>
    </div>
</div>
