import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CardProfile } from 'muchet-models';
import { catchError, switchMap } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { fade, fadeUp, vshrink } from '../../../utils/animations';
import { HttpClient } from '@angular/common/http';
import { fileToBase64 } from '../../../utils/file-utils';
import * as FileSaver from 'file-saver';
import { of } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
    template: '',
    animations: [fadeUp(), vshrink(), fade('loading', '.5s ease')],
})
export abstract class ProfileComponent {
    profile: CardProfile;
    headerImageStyle: SafeStyle;
    avatarImage: string;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input() cardId: string;

    @Input('profile') set setProfile(value: CardProfile) {
        this.profile = value;
        this.headerImageStyle = null;
        this.avatarImage = null;
        if (this.profile) this.fetchImages();
    }

    protected constructor(
        protected sanitizer: DomSanitizer,
        protected storage: AngularFireStorage,
        protected http: HttpClient
    ) {}

    private async fetchImages() {
        // Fetch image urls from storage
        const [headerImageUrl, avatarImageUrl]: [string, string] =
            await Promise.all([
                this.profile?.headerPath
                    ? this.storage
                          .ref(this.profile.headerPath)
                          .getDownloadURL()
                          .pipe(catchError(() => of(null)))
                          .toPromise()
                    : Promise.resolve('/assets/img/default_header.jpg'),
                this.profile?.avatarPath
                    ? this.storage
                          .ref(this.profile.avatarPath)
                          .getDownloadURL()
                          .pipe(catchError(() => of(null)))
                          .toPromise()
                    : Promise.resolve('/assets/img/default_avatar.png'),
            ]);
        // Retrieve image data
        const [headerImage, avatarImage]: [string, string] = await Promise.all([
            this.http
                .get(headerImageUrl, { responseType: 'blob' })
                .pipe(switchMap((data) => fileToBase64(data)))
                .toPromise(),
            this.http
                .get(avatarImageUrl, { responseType: 'blob' })
                .pipe(switchMap((data) => fileToBase64(data)))
                .toPromise(),
        ]);
        // Set image data
        this.headerImageStyle = this.sanitizer.bypassSecurityTrustStyle(
            `background-image: url('${headerImage}')`
        );
        this.avatarImage = avatarImage;
    }

    get singleEmailAddress() {
        return Object.values(this.profile.email as any)[0];
    }

    get emailAddressCount() {
        return Object.values(this.profile.email ?? ({} as any)).filter(
            (v) => !!v
        ).length;
    }

    get singlePhoneNumber() {
        return Object.values(this.profile.telephone as any)[0];
    }

    get phoneNumberCount() {
        return Object.values(this.profile.telephone ?? ({} as any)).filter(
            (v) => !!v
        ).length;
    }

    get websiteUrl() {
        if (!this.profile?.website) return '#';
        const url = this.profile.website;
        return !url.startsWith('http://') && !url.startsWith('https://')
            ? `http://${url}`
            : url;
    }

    get tagLine() {
        return [this.profile.jobPosition, this.profile.companyName]
            .filter((t) => !!t)
            .join(' · ');
    }

    get profileUrl(): string {
        return environment.baseUrl + '/p/' + this.cardId;
    }

    async saveContact() {
        const avatarData = await (async () => {
            const avatarUrl = this.profile.avatarPath
                ? `https://storage.googleapis.com/${environment.firebase.storageBucket}/userdata/${this.profile.userId}/profile/${this.cardId}/img/avatar`
                : '/assets/img/default_avatar.png';
            console.log(this.profile, avatarUrl);
            const b64Data: string = await this.http
                .get(avatarUrl, { responseType: 'blob' })
                .toPromise()
                .then((data) => fileToBase64(data));
            const delimiterIndex = b64Data.indexOf(';');
            if (delimiterIndex <= 0) return null;
            const mediaType = b64Data
                .substring('data:image/'.length, delimiterIndex)
                .toUpperCase();
            return {
                url: b64Data.substring(b64Data.indexOf(',')),
                mediaType,
                base64: true,
            };
        })();
        const vCardData = await this.profile.toVCard(avatarData);
        const blob = new Blob([vCardData], {
            type: 'text/vcard;charset=utf-8',
        });
        FileSaver.saveAs(
            blob,
            this.profile.fullName
                .replace(/[^a-zA-Z ]/g, '')
                .replace(/\s+/g, '_') + '.vcf'
        );
    }
}
