import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';
import { environment } from '../../environments/environment';
import { CheckoutStartParams } from 'muchet-models';
import User = firebase.User;
import StripeClient = stripe.Stripe;

@Injectable({
    providedIn: 'root',
})
export class CheckoutService {
    stripe: StripeClient;

    constructor(
        private func: AngularFireFunctions,
        private auth: AngularFireAuth
    ) {
        this.stripe = Stripe(environment.stripe.publishableKey);
    }

    async checkout(productId: string) {
        const user: User = await this.auth.currentUser;
        if (!user || !user.emailVerified)
            throw {
                code: 'not_authenticated',
                description: 'Cannot checkout products when unauthenticated.',
            };
        const startCheckout = this.func.httpsCallable('checkoutStart', {});
        let stripeSessionId: string;
        try {
            ({ stripeSessionId } = await startCheckout({
                productId,
                successUrl:
                    environment.baseUrl + '/checkout/post?status=success',
                cancelUrl:
                    environment.baseUrl + '/checkout/post?status=failure',
            } as CheckoutStartParams).toPromise());
        } catch (e) {
            throw {
                code: 'unknown_error',
                description:
                    'An unknown error occurred while trying to start checkout',
                details: e,
            };
            return;
        }
        const resp = await this.stripe.redirectToCheckout({
            sessionId: stripeSessionId,
        });
        if (resp && resp.error) {
            throw {
                code: 'stripe_error',
                description: resp.error.message,
                extra: resp.error,
            };
        }
    }
}
