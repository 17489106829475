import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OperationStatus } from '../../models/operation-status.model';
import { fadeUp, hshrink, vshrink } from '../../utils/animations';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import { ModalService } from '../../services/modal.service';
import {
    ConfirmationModalComponent,
    ConfirmationModalInput,
} from '../../components/confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'app-login-view',
    templateUrl: './login-view.component.html',
    styleUrls: ['./login-view.component.scss'],
    animations: [fadeUp(), hshrink(), vshrink()],
})
export class LoginViewComponent {
    mode: 'REGISTER' | 'LOGIN' | 'VERIFY_EMAIL' = 'LOGIN';
    email = '';
    password = '';
    loginError = '';
    passwordConfirm = '';
    displayName = '';
    loginStatus: OperationStatus = 'IDLE';
    googleLoginStatus: OperationStatus = 'IDLE';
    registerError: string;
    registerStatus: OperationStatus = 'IDLE';

    get validRegistrationDetails(): boolean {
        return !!(
            this.email.trim() &&
            this.password.trim() &&
            this.displayName.trim() &&
            this.password === this.passwordConfirm
        );
    }

    constructor(
        private router: Router,
        public auth: AngularFireAuth,
        private modalService: ModalService
    ) {}

    async onLoginClick() {
        if (!['IDLE', 'ERROR'].includes(this.loginStatus)) return;
        this.loginError = null;
        this.registerError = null;
        this.loginStatus = 'IN_PROGRESS';
        try {
            const credential = await this.auth.signInWithEmailAndPassword(
                this.email,
                this.password
            );
            if (!credential.user.emailVerified) {
                await this.auth.signOut();
                throw { code: 'auth/email-not-verified' };
            }
            this.loginStatus = 'SUCCESS';
            setTimeout(() => {
                this.router.navigate(['portal']);
            }, 1000);
        } catch (e) {
            console.error(e);
            this.loginStatus = 'ERROR';
            switch (e?.code || '') {
                case 'auth/network-request-failed':
                    this.loginError =
                        'The server could not be reached. Please try again later.';
                    break;
                case 'auth/invalid-email':
                case 'auth/user-not-found':
                    this.loginError = 'The provided credentials are invalid.';
                    break;
                case 'auth/email-not-verified':
                    this.loginError =
                        'You cannot log in before verifying your email address.';
                    break;
                // TODO: HANDLE OTHER ERRORS (e.g. RATE LIMIT)

                default:
                    this.loginError = 'An unknown error occurred.';
            }
        }
    }

    async onRegisterClick() {
        if (!['IDLE', 'ERROR'].includes(this.registerStatus)) return;
        this.registerError = null;
        this.loginError = null;
        this.registerStatus = 'IN_PROGRESS';
        try {
            const credential = await this.auth.createUserWithEmailAndPassword(
                this.email,
                this.password
            );
            await credential.user.sendEmailVerification();
            await credential.user.updateProfile({
                displayName: this.displayName,
            });
            await this.auth.signOut();
            this.registerStatus = 'SUCCESS';
            setTimeout(() => this.setMode('VERIFY_EMAIL'), 1000);
        } catch (e) {
            console.error(e);
            this.registerStatus = 'ERROR';
            switch (e?.code || '') {
                case 'auth/network-request-failed':
                    this.registerError =
                        'The server could not be reached. Please try again later.';
                    break;
                case 'auth/invalid-email':
                    this.registerError =
                        'The email you have provided is invalid.';
                    break;
                case 'auth/email-already-in-use':
                    this.registerError =
                        'The email you provided is already in use.';
                    break;
                case 'auth/weak-password':
                    this.registerError = 'Weak password. ' + e?.message + '.';
                    break;
                // TODO: HANDLE OTHER ERRORS (e.g. RATE LIMIT)
                default:
                    this.registerError = 'An unknown error occurred.';
            }
        }
    }

    setMode(mode: 'REGISTER' | 'LOGIN' | 'VERIFY_EMAIL') {
        this.mode = mode;
        this.password = '';
        this.passwordConfirm = '';
    }

    async onGoogleLoginClick() {
        if (!['IDLE', 'ERROR'].includes(this.googleLoginStatus)) return;
        this.loginError = null;
        this.registerError = null;
        this.googleLoginStatus = 'IN_PROGRESS';
        try {
            await this.auth.signInWithPopup(new GoogleAuthProvider());
            this.googleLoginStatus = 'SUCCESS';
            setTimeout(() => {
                this.router.navigate(['portal']);
            }, 1000);
        } catch (e) {
            console.error(e);
            this.googleLoginStatus = 'IDLE';
        }
    }

    async onForgetPassword() {
        if (!this.email) {
            this.loginError =
                'To request a password reset, fill in your email address first!';
            return;
        }
        try {
            await this.auth.sendPasswordResetEmail(this.email, {
                url: window.location.origin + '/login',
            });
            await this.modalService
                .showModal<ConfirmationModalComponent, ConfirmationModalInput>(
                    ConfirmationModalComponent,
                    {
                        title: 'Forgotten password',
                        message:
                            'An email has been sent to your mailbox with instructions on how to reset your password!',
                        confirmText: 'Alright',
                        showCancel: false,
                    }
                )
                .toPromise();
        } catch (e) {
            console.error(e);
            await this.modalService
                .showModal<ConfirmationModalComponent, ConfirmationModalInput>(
                    ConfirmationModalComponent,
                    {
                        title: 'Could not reset password',
                        message:
                            'A password reset could not be requested. Please try again later.',
                        confirmText: 'Ok',
                        showCancel: false,
                    }
                )
                .toPromise();
        }
    }
}
