<div class="page-bar main-info">
    <div class="curved-div">
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 800 600"
            preserveAspectRatio="none"
        >
            <defs>
                <linearGradient id="gradient" gradientTransform="rotate(90)">
                    <stop offset="0%" stop-color="#110F24" />
                    <stop offset="100%" stop-color="#211B4E" />
                </linearGradient>
            </defs>
            <path
                fill="url(#gradient)"
                d="M 0 200 C 450 200 400 600 800 600 L 800 0 L 0 0 Z"
            ></path>
        </svg>
    </div>
    <app-landing-nav-bar></app-landing-nav-bar>
    <div class="page-container">
        <div class="grid xs-1 sm-2">
            <div>
                <h1>Your new Profile Card</h1>
                <p>
                    It’s time to join the 21st century by throwing your paper
                    business cards away and replace it with a single stainless
                    steel NFC Profile Card.
                </p>
                <button class="btn btn-primary" (click)="onClickLearnMore()">
                    Learn More
                </button>
            </div>
            <div class="media-container">
                <img src="/assets/img/card.png" style="max-width: 400px" />
            </div>
            <div>
                <h2>Tap and Share all your favorite socials in seconds</h2>
                <p>
                    Simply tap the card against a smartphone and share your
                    e-mail, phone Number, Instagram, LinkedIn and many more.
                </p>
            </div>
            <div class="media-container">
                <video playsinline autoplay muted loop>
                    <source
                        src="/assets/img/phone_animation.webm"
                        type="video/webm"
                    />
                </video>
            </div>
            <div class="media-container">
                <img src="/assets/img/profile_screenshot.png" />
            </div>
            <div>
                <h2>
                    Create & edit your profile card on our newly designed
                    platform
                </h2>
                <p>
                    Here you can add, edit & enable what you would like to share
                    with your new metal card. You can try it out before you buy
                    our card.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="page-bar pricing-info" id="learnMore">
    <div class="page-container">
        <h2 class="title">
            No&nbsp;Subscriptions. No&nbsp;adverts.<br />
            Just.&nbsp;One.&nbsp;Price.
        </h2>
        <span class="tag-line"
            >We believe in the quality of our products and we would like to
            share it with as many people as possible. That’s why we have
            included Free Shipping, lifetime use of our platform and 14 days
            return.</span
        >
        <div class="grid xs-1 sm-3">
            <div class="step">
                <span class="step-pre-text">Step 1</span>
                <span class="step-title">Purchase</span>
                <span class="step-content"
                    >Sign up and add the information you want to share with
                    people, like your socials on a party or your e-mail at a
                    business meeting.</span
                >
            </div>
            <div class="step">
                <span class="step-pre-text">Step 2</span>
                <span class="step-title">Create your profile</span>
                <span class="step-content"
                    >Buy one of our cards for a single price. We offer free
                    shipping in the whole EU!</span
                >
            </div>
            <div class="step">
                <span class="step-pre-text">Step 3</span>
                <span class="step-title">Tap & Share</span>
                <span class="step-content"
                    >Once your card is in your hands you can just tap and share!
                    No apps required.</span
                >
            </div>
        </div>
    </div>
</div>

<div class="page-bar testimonial-info">
    <div class="page-container">
        <div class="left">
            <span class="author-line"
                >Olaf Vrijmoet, founder of EtAlia, says:</span
            >
            <span class="testimonial-line">
                "It’s so nice to add all your info on the client’s phone in just
                a matter of seconds!"
            </span>
        </div>
        <div class="right"></div>
    </div>
</div>

<div class="page-bar bottom-info">
    <div class="page-container">
        <div class="purchase-info-header">
            <h2>Purchase Your Card</h2>
        </div>
        <div class="grid xs-1 sm-2">
            <div class="purchase-info">
                <img src="/assets/img/card.png" class="card-img" />
                <h3>Muchet Classic</h3>
                <ul>
                    <li>Matte stainless steel card</li>
                    <li>NFC Enabled</li>
                    <li>Lifetime use of our platform</li>
                    <li>Shipped within 2 days</li>
                </ul>
                <button class="btn-purchase" routerLink="/login">€49,99</button>
            </div>
            <div class="purchase-info">
                <img src="/assets/img/card_black.png" class="card-img" />
                <h3>Muchet Black</h3>
                <ul>
                    <li>Black & Gold stainless steel card</li>
                    <li>NFC Enabled</li>
                    <li>Lifetime use of our platform</li>
                    <li>Invite Only</li>
                </ul>
                <a class="btn btn-purchase" href="mailto:info@muchet.com"
                    >Invite Only</a
                >
            </div>
        </div>
        <div class="get-started">
            <div class="left">
                <h2>Ready to get started?</h2>
                <h3>Create an account and get one of our cards</h3>
            </div>
            <div class="right">
                <button class="btn-get-started" routerLink="/login">
                    Create An Account
                </button>
            </div>
        </div>
        <app-landing-footer></app-landing-footer>
    </div>
</div>
