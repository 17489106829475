<app-portal-nav-bar></app-portal-nav-bar>
<div class="page-container">
    <div class="card" *ngIf="status === 'SUCCESS'">
        <h2>Payment succeeded</h2>
        <button class="btn btn-primary" [routerLink]="['portal']">
            Go to portal
        </button>
    </div>
    <div class="card" *ngIf="status === 'FAILURE'">
        <h2>Payment failed</h2>
        <button class="btn btn-primary" [routerLink]="['portal']">
            Go to portal
        </button>
    </div>
</div>
