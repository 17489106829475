<app-portal-nav-bar></app-portal-nav-bar>
<div class="page-container">
    <div class="page-header">
        <div>Your Orders</div>
    </div>
    <div class="card no-padding">
        <div class="orders-table">
            <div class="orders-table-inner">
                <div class="orders-header">
                    <div>Order ID</div>
                    <div>Product Name</div>
                    <div>Order Status</div>
                    <div>Price</div>
                    <div>Address</div>
                    <div>Order Creation</div>
                    <div>Last Updated</div>
                </div>
                <div class="order-row" *ngIf="!orders?.length" @vshrink>
                    <div class="no-orders">
                        <span>You currently have no orders!</span>
                    </div>
                </div>
                <div class="order-row" *ngFor="let order of orders" @vshrink>
                    <div>{{ order.id }}</div>
                    <div>{{ order.productName }}</div>
                    <div>
                        <div class="order-status" [ngClass]="order.statusClass">
                            {{ order.statusLabel }}
                        </div>
                    </div>
                    <div>{{ order.price }}</div>
                    <div style="white-space: pre">{{ order.address }}</div>
                    <div>{{ order.creationDate }}</div>
                    <div>{{ order.lastUpdated }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
