import { Component, OnInit } from '@angular/core';
import { fade, vshrink } from '../../utils/animations';

@Component({
    selector: 'app-landing-nav-bar',
    templateUrl: './landing-nav-bar.component.html',
    styleUrls: ['./landing-nav-bar.component.scss'],
    animations: [fade(), vshrink()],
})
export class LandingNavBarComponent implements OnInit {
    showMobileNav = false;

    constructor() {}

    ngOnInit(): void {}
}
