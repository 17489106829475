<div class="header-image" [style]="headerImageStyle">
    <button class="vcard-button" (click)="saveContact()">
        <span>
            <i class="material-icons">person_add</i>
            Save contact
        </span>
    </button>
    <div class="loading" [@loading] *ngIf="!headerImageStyle"></div>
</div>
<div class="profile-body">
    <div class="avatar-container">
        <div class="avatar">
            <img [srcset]="avatarImage" *ngIf="avatarImage" />
            <div class="loading" [@loading] *ngIf="!avatarImage">
                <app-spinner></app-spinner>
            </div>
        </div>
    </div>
    <div class="profile-header">
        <div class="name">{{ profile.fullName }}</div>
        <div class="tagline" *ngIf="tagLine" @vshrink>{{ tagLine }}</div>
    </div>
    <div class="profile-values">
        <a
            class="profile-value icon-value"
            *ngIf="profile.aboutText"
            @vshrink
        >
            <div class="icon">
                <i class="material-icons">account_box</i>
            </div>
            <div class="label">
                <div class="aboutme">
                    {{ profile.aboutText }}
                </div>
            </div>
        </a>
        <a class="profile-value header" *ngIf="emailAddressCount >= 2" @vshrink>
            <div class="icon">
                <i class="material-icons">email</i>
            </div>
            <div class="label">
                <span>E-mail</span>
            </div>
        </a>
        <a
            class="profile-value icon-value"
            href="mailto:{{ singleEmailAddress }}"
            *ngIf="emailAddressCount === 1"
            @vshrink
        >
            <div class="icon">
                <i class="material-icons">email</i>
            </div>
            <div class="label">
                <span>{{ singleEmailAddress }}</span>
            </div>
        </a>
        <a
            class="profile-value key-value"
            href="mailto:{{ profile.email?.personalAddress }}"
            *ngIf="emailAddressCount >= 2 && profile.email?.personalAddress"
            @vshrink
        >
            <div class="key">
                <span>Personal</span>
            </div>
            <div class="value">
                <span>{{ profile.email?.personalAddress }}</span>
            </div>
        </a>
        <a
            class="profile-value key-value"
            href="mailto:{{ profile.email?.workAddress }}"
            *ngIf="emailAddressCount >= 2 && profile.email?.workAddress"
            @vshrink
        >
            <div class="key">
                <span>Work</span>
            </div>
            <div class="value">
                <span>{{ profile.email?.workAddress }}</span>
            </div>
        </a>
        <a class="profile-value header" *ngIf="phoneNumberCount >= 2" @vshrink>
            <div class="icon">
                <i class="material-icons">call</i>
            </div>
            <div class="label">
                <span>Telephone</span>
            </div>
        </a>
        <a
            class="profile-value icon-value"
            href="tel:{{ singlePhoneNumber }}"
            *ngIf="phoneNumberCount === 1"
            @vshrink
        >
            <div class="icon">
                <i class="material-icons">call</i>
            </div>
            <div class="label">
                <span>{{ singlePhoneNumber }}</span>
            </div>
        </a>
        <a
            class="profile-value key-value"
            href="tel:{{ profile.telephone?.homeNumber }}"
            *ngIf="phoneNumberCount >= 2 && profile.telephone?.homeNumber"
        >
            <div class="key">
                <span>Home</span>
            </div>
            <div class="value">
                <span>{{ profile.telephone?.homeNumber }}</span>
            </div>
        </a>
        <a
            class="profile-value key-value"
            href="tel:{{ profile.telephone?.workNumber }}"
            *ngIf="phoneNumberCount >= 2 && profile.telephone?.workNumber"
        >
            <div class="key">
                <span>Work</span>
            </div>
            <div class="value">
                <span>{{ profile.telephone?.workNumber }}</span>
            </div>
        </a>
        <a
            class="profile-value key-value"
            href="tel:{{ profile.telephone?.mobileNumber }}"
            *ngIf="phoneNumberCount >= 2 && profile.telephone?.mobileNumber"
        >
            <div class="key">
                <span>Mobile</span>
            </div>
            <div class="value">
                <span>{{ profile.telephone?.mobileNumber }}</span>
            </div>
        </a>
        <a
            class="profile-value icon-value"
            [href]="websiteUrl"
            *ngIf="profile.website"
            @vshrink
        >
            <div class="icon">
                <i class="material-icons">public</i>
            </div>
            <div class="label">
                <span>{{ profile.website }}</span>
            </div>
        </a>
        <a
            class="profile-value icon-value"
            href="https://www.linkedin.com/in/{{
                profile.socials?.linkedInHandle
            }}/"
            *ngIf="profile.socials?.linkedInHandle"
            @vshrink
        >
            <div class="icon">
                <fa-icon
                    [icon]="['fab', 'linkedin']"
                    style="color: #0077b5"
                ></fa-icon>
            </div>
            <div class="label">
                <span>LinkedIn</span>
            </div>
        </a>
        <a
            class="profile-value icon-value"
            href="https://twitter.com/{{ profile.socials?.twitterHandle }}"
            *ngIf="profile.socials?.twitterHandle"
            @vshrink
        >
            <div class="icon">
                <fa-icon
                    [icon]="['fab', 'twitter']"
                    style="color: #55acee"
                ></fa-icon>
            </div>
            <div class="label">
                <a>@{{ profile.socials?.twitterHandle }}</a>
            </div>
        </a>
        <a
            class="profile-value icon-value"
            href="https://www.instagram.com/{{
                profile.socials?.instagramHandle
            }}/"
            *ngIf="profile.socials?.instagramHandle"
            @vshrink
        >
            <div class="icon">
                <fa-icon
                    [icon]="['fab', 'instagram']"
                    style="color: #e4405f"
                ></fa-icon>
            </div>
            <div class="label">
                <span>@{{ profile.socials?.instagramHandle }}</span>
            </div>
        </a>
        <a
            class="profile-value icon-value"
            href="https://www.facebook.com/{{
                profile.socials?.facebookHandle
            }}/"
            *ngIf="profile.socials?.facebookHandle"
            @vshrink
        >
            <div class="icon">
                <fa-icon
                    [icon]="['fab', 'facebook']"
                    style="color: #4267b2"
                ></fa-icon>
            </div>
            <div class="label">
                <span>@{{ profile.socials?.facebookHandle }}</span>
            </div>
        </a>
        <a
            class="profile-value icon-value"
            href="https://www.tiktok.com/@{{ profile.socials?.tikTokHandle }}/"
            *ngIf="profile.socials?.tikTokHandle"
            @vshrink
        >
            <div class="icon">
                <fa-icon
                    [icon]="['fab', 'tiktok']"
                    style="color: #000000"
                ></fa-icon>
            </div>
            <div class="label">
                <span>@{{ profile.socials?.tikTokHandle }}</span>
            </div>
        </a>
    </div>
    <div class="profile-qrcode">
        <div class="title">Profile Page:</div>
        <qr-code [value]="profileUrl" [size]="512" [canvas]="true"></qr-code>
    </div>
</div>
