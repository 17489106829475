import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-markdown-view',
    templateUrl: './markdown-view.component.html',
    styleUrls: ['./markdown-view.component.scss'],
})
export class MarkdownViewComponent implements OnInit {
    markdown: Observable<string>;
    title: Observable<string>;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.markdown = this.route.data.pipe(map((data) => data.markdown));
        this.title = this.route.data.pipe(map((data) => data.title));
    }
}
