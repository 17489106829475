<app-portal-nav-bar></app-portal-nav-bar>
<div class="page-container">
    <div class="page-header" *ngIf="user" @vshrink>
        <div>
            <span>Hello,&nbsp;</span>
            <wbr />
            <span>{{ user.displayName }}</span>
        </div>
    </div>
    <div class="section-header">
        <h1 class="section-header-title">YOUR CARDS</h1>
    </div>
    <div class="grid xs-1" [ngClass]="cards?.length >= 2 ? 'sm-2' : 'sm-1'">
        <div class="muchet-card buy-card" *ngIf="cards?.length === 0" @fadeUp>
            <div class="card no-padding">
                <div class="muchet-card-image">
                    <div class="card-image-wrapper">
                        <img
                            src="/assets/img/card.png"
                            alt="Muchet Card Image"
                        />
                    </div>
                    <div class="image-icon">
                        <i class="material-icons">local_grocery_store</i>
                    </div>
                </div>
                <div class="muchet-card-content">
                    <div class="title">You do not have any cards yet</div>
                    <div class="tagline">
                        When you purchase a card successfully, it will show up
                        here!
                    </div>
                    <div class="actions">
                        <button
                            class="btn btn-primary"
                            (click)="purchaseCard()"
                        >
                            <i class="material-icons">add_shopping_cart</i>
                            Purchase
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="muchet-card"
            *ngFor="let card of cards; trackBy: trackCardBy"
            @fadeUp
        >
            <div class="card no-padding">
                <div class="muchet-card-image">
                    <div class="card-image-wrapper">
                        <img
                            src="/assets/img/card.png"
                            alt="Muchet Card Image"
                        />
                    </div>
                </div>
                <div class="muchet-card-content" *ngIf="card.profileSummary">
                    <div class="title">{{ card.profileSummary.fullName }}</div>
                    <div
                        class="tagline"
                        *ngIf="
                            card.profileSummary.jobPosition ||
                            card.profileSummary.companyName
                        "
                    >
                        {{
                            [
                                card.profileSummary.jobPosition,
                                card.profileSummary.companyName
                            ].join(' · ')
                        }}
                    </div>
                    <div class="actions">
                        <button
                            class="btn btn-primary"
                            (click)="viewProfile(card.id)"
                        >
                            <i class="material-icons">person</i>View Profile
                        </button>
                        <button
                            class="btn btn-secondary"
                            (click)="editProfile(card.id)"
                        >
                            <i class="material-icons">edit</i>Edit Profile
                        </button>
                    </div>
                </div>
                <div class="muchet-card-content" *ngIf="!card.profileSummary">
                    <div class="title">New Card</div>
                    <div class="tagline">
                        You have not yet created a profile for this card!
                    </div>
                    <div class="actions">
                        <button
                            class="btn btn-primary"
                            (click)="editProfile(card.id)"
                        >
                            <i class="material-icons">add</i>
                            Create Profile
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--        <div class="card">-->
    <!--            <h2>Buy (another) card placeholder</h2>-->
    <!--            <p>SMALL ENTICING DESCRIPTION</p>-->
    <!--            <p>DIFFERENT WHEN USER DOES NOT OWN ANY CARDS YET</p>-->
    <!--            <p>DIFFERENT IMAGE SHOWCASING CARD</p>-->
    <!--            <div class="card-action-bar">-->
    <!--                <button class="btn btn-primary" routerLink="/checkout/pre">-->
    <!--                    <i class="material-icons">shopping_cart</i>Buy Card-->
    <!--                </button>-->
    <!--            </div>-->
    <!--        </div>-->
</div>
