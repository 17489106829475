<input
    type="file"
    class="file-selector animate__animated"
    (change)="fileChangeEvent(fileSelector.files)"
    accept="image/x-png,image/jpeg"
    #fileSelector
/>
<div
    class="image-container animate__animated"
    [class.animate__headShake]="_shakeImagePreview"
>
    <img [srcset]="imageUrl" />
    <button
        class="image-container-overlay"
        [class.no-image]="!imageData"
        (click)="fileSelector.click()"
    >
        <i class="material-icons">cloud_upload</i>
        <span class="instructions">Upload image</span>
        <span class="max-file-size">(Max. {{ fileSizeLimitMb }} Mb)</span>
    </button>
</div>
