<div class="profile" *ngIf="profile" @fadeUp>
    <app-profile-mobile
        class="sm-hidden"
        [profile]="profile"
        [cardId]="cardId"
    ></app-profile-mobile>
    <app-profile-desktop
        class="xs-hidden"
        [profile]="profile"
        [cardId]="cardId"
    ></app-profile-desktop>
</div>
<div class="no-profile" *ngIf="!hasProfile">
    <div class="title">This profile does not (yet) exist.</div>
    <div class="message">
        Did you just get this card? Set up a profile for it in the
        <a [routerLink]="['portal']">Portal</a>!
    </div>
</div>
