/* eslint-disable @typescript-eslint/naming-convention */
export const ORDER_STATUS_MAP = {
    AWAITING_PAYMENT: {
        label: 'Awaiting Payment',
        className: 'awaiting-payment',
    },
    PAYMENT_CANCELLED: {
        label: 'Payment Cancelled',
        className: 'payment-cancelled',
    },
    PAYMENT_FAILED: {
        label: 'Payment Failed',
        className: 'payment-failed',
    },
    PAYMENT_PROCESSING: {
        label: 'Payment Processing',
        className: 'payment-processing',
    },
    PAYMENT_SUCCEEDED: {
        label: 'Payment Succeeded',
        className: 'payment-succeeded',
    },
    SHIPPED: {
        label: 'Shipped',
        className: 'shipped',
    },
    COMPLETED: {
        label: 'Completed',
        className: 'completed',
    },
    RETURNED: {
        label: 'Returned',
        className: 'returned',
    },
};
