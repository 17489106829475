<app-portal-nav-bar></app-portal-nav-bar>
<div class="page-container">
    <div class="page-header">
        <div>
            <span>Purchase</span>
        </div>
    </div>
    <div class="card">
        <div class="purchase-row" *ngFor="let product of products | async" @vshrink>
            <div class="purchase-row-image">
                <img *ngIf="product.images.length" [srcset]="product.images[0]">
            </div>
            <div class="purchase-row-name">{{ product.name }}</div>
            <div class="purchase-row-checkout">
                <button class="btn btn-primary" (click)="checkout(product.id)" [disabled]="checkingOutId">
                    <i class="material-icons" @hshrink *ngIf="checkingOutId !== product.id">shopping_cart</i>
                    <span @hshrink *ngIf="checkingOutId !== product.id">Checkout</span>
                    <app-spinner size="SMALL" *ngIf="checkingOutId === product.id" @hshrink></app-spinner>
                </button>
            </div>
        </div>
    </div>
</div>
