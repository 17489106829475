import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';

@Component({
    selector: 'app-home-view',
    templateUrl: './home-view.component.html',
    styleUrls: ['./home-view.component.scss'],
})
export class HomeViewComponent implements OnInit, OnDestroy {
    constructor(private themeService: ThemeService) {}

    ngOnInit(): void {
        this.themeService.setMode('LIGHT', false);
    }

    ngOnDestroy(): void {
        this.themeService.setMode();
    }

    onClickLearnMore() {
        document.getElementById('learnMore').scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'nearest',
        });
    }
}
