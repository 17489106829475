export const environment = {
    env: 'TESTING',
    useEmulators: false,
    stripe: {
        publishableKey:
            'pk_test_51JidpuFbm93C1H69cCXfjxtsz6Wu1LgiNprBXarAUzVdidNr1LuH0fMCGEEcoitpJHPFxFiHySrdD7NAo9rHW10b00BpEoxVxx',
    },
    firebase: {
        apiKey: 'AIzaSyAKduhzmYGTivA7wv6bNBW8ZcMplYeM0wo',
        authDomain: 'muchet-testing.firebaseapp.com',
        projectId: 'muchet-testing',
        storageBucket: 'muchet-testing.appspot.com',
        messagingSenderId: '514381476036',
        appId: '1:514381476036:web:341174651360bf0c511991',
        measurementId: 'G-81BVNTQ5K5',
    },
    baseUrl: 'https://testing.muchet.com',
};
