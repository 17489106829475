import {
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { fade, fadeUp, triggerChildren } from '../../../utils/animations';
import { Modal } from '../../../services/modal.service';
import * as Croppie from 'croppie';
import { fileToBase64 } from '../../../utils/file-utils';

export type AvatarCropperModalInput = {
    image: Blob;
};

export type AvatarCropperModalOutput = {
    image: Blob;
};

@Component({
    selector: 'app-avatar-cropper-modal',
    templateUrl: './avatar-cropper-modal.component.html',
    styleUrls: ['./avatar-cropper-modal.component.scss'],
    animations: [
        triggerChildren('triggerModal', '@modal'),
        fade('bg', '0.4s ease'),
        fadeUp('modal', '0.4s ease'),
    ],
})
export class AvatarCropperModalComponent
    extends Modal<AvatarCropperModalInput, AvatarCropperModalOutput>
    implements OnDestroy {
    input: AvatarCropperModalInput;
    croppie: Croppie;

    @ViewChild('croppingContainer') croppingContainer: ElementRef;

    constructor() {
        super();
    }

    ngOnDestroy() {
        this.croppie?.destroy();
    }

    @HostListener('document:keydown.escape')
    onEscapeDown() {
        this.onCancel();
    }

    onCancel() {
        this.emit(null);
        this.close();
    }

    async onSave() {
        this.emit({
            image: await this.croppie.result({ type: 'blob', circle: false }),
        });
        this.close();
    }

    async initModal(input: AvatarCropperModalInput) {
        this.input = input;
        this.croppie?.destroy();
        const imageData = await fileToBase64(input.image);
        setTimeout(() => {
            this.croppie = new Croppie(this.croppingContainer.nativeElement, {
                viewport: { width: 256, height: 256, type: 'circle' },
            });
            this.croppie.bind({
                url: imageData,
            });
        });
    }
}
