import { BrowserModule } from '@angular/platform-browser';
import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
} from '@angular/core';
import {
    AngularFireAuthModule,
    USE_EMULATOR as USE_AUTH_EMULATOR,
} from '@angular/fire/auth';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import {
    AngularFireFunctionsModule,
    REGION,
    USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
} from '@angular/fire/functions';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginViewComponent } from './views/login-view/login-view.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { PortalViewComponent } from './views/portal-view/portal-view.component';
import { CollapsibleComponent } from './components/collapsible/collapsible.component';
import { GenericContextMenuComponent } from './components/generic-context-menu/generic-context-menu.component';
import { SafePopupBaseComponent } from './components/safe-popup-base/safe-popup-base.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { ProfileViewComponent } from './views/profile-view/profile-view.component';
import { ProfileEditViewComponent } from './views/profile-edit-view/profile-edit-view.component';
import { PortalNavBarComponent } from './components/portal-nav-bar/portal-nav-bar.component';
import { LogoComponent } from './components/logo/logo.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { PreCheckoutViewComponent } from './views/pre-checkout-view/pre-checkout-view.component';
import { PostCheckoutViewComponent } from './views/post-checkout-view/post-checkout-view.component';
import { PortalNavBarItemComponent } from './components/portal-nav-bar/portal-nav-bar-item/portal-nav-bar-item.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { OrdersViewComponent } from './views/orders-view/orders-view.component';
import {
    FaIconLibrary,
    FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { AppInitService } from './services/app-init.service';
import { AvatarUploaderComponent } from './components/avatar-uploader/avatar-uploader.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { AvatarCropperModalComponent } from './components/avatar-uploader/avatar-cropper-modal/avatar-cropper-modal.component';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { VarDirective } from './directives/var.directive';
import { ProfileDesktopComponent } from './views/profile-view/profile/profile-desktop/profile-desktop.component';
import { ProfileMobileComponent } from './views/profile-view/profile/profile-mobile/profile-mobile.component';
import { OrderViewComponent } from './views/order-view/order-view.component';
import { HomeViewComponent } from './views/home-view/home-view.component';
import { LandingNavBarComponent } from './components/landing-nav-bar/landing-nav-bar.component';
import { AboutViewComponent } from './views/about-view/about-view.component';
import { LandingFooterComponent } from './components/landing-footer/landing-footer.component';
import { LandingStandardLayoutComponent } from './components/landing-standard-layout/landing-standard-layout.component';
import { MarkdownViewComponent } from './views/markdown-view/markdown-view.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { QRCodeModule } from 'angular2-qrcode';

export function initializeApp(appInitService: AppInitService) {
    return () => appInitService.init();
}

@NgModule({
    declarations: [
        AppComponent,
        LoginViewComponent,
        PortalViewComponent,
        CollapsibleComponent,
        GenericContextMenuComponent,
        SafePopupBaseComponent,
        SpinnerComponent,
        ProfileViewComponent,
        ProfileEditViewComponent,
        PortalNavBarComponent,
        LogoComponent,
        ClickOutsideDirective,
        PreCheckoutViewComponent,
        PostCheckoutViewComponent,
        PortalNavBarItemComponent,
        ConfirmationModalComponent,
        OrdersViewComponent,
        ProfileMobileComponent,
        ProfileDesktopComponent,
        AvatarUploaderComponent,
        ImageUploaderComponent,
        AvatarCropperModalComponent,
        VarDirective,
        OrderViewComponent,
        HomeViewComponent,
        LandingNavBarComponent,
        AboutViewComponent,
        LandingFooterComponent,
        LandingStandardLayoutComponent,
        MarkdownViewComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MarkedOptions,
                useValue: {
                    gfm: true,
                    breaks: true,
                },
            },
        }),
        FormsModule,
        AppRoutingModule,
        MomentModule,
        NgPipesModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFireFunctionsModule,
        AngularFireStorageModule,
        FontAwesomeModule,
        QRCodeModule,
    ],
    providers: [
        {
            provide: USE_AUTH_EMULATOR,
            useValue: environment.useEmulators
                ? ['localhost', 9099]
                : undefined,
        },
        {
            provide: USE_FIRESTORE_EMULATOR,
            useValue: environment.useEmulators
                ? ['localhost', 8080]
                : undefined,
        },
        {
            provide: USE_FUNCTIONS_EMULATOR,
            useValue: environment.useEmulators
                ? ['localhost', 5001]
                : undefined,
        },
        { provide: REGION, useValue: 'europe-west1' },
        AppInitService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppInitService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas, far, fab);
    }
}
