<div class="row-container">
    <div class="left">
        <button
            class="btn-xs-menu sm-hidden"
            (click)="showMobileNav = !showMobileNav"
        >
            <i class="material-icons">menu</i>
        </button>
        <app-logo [style]="'LIGHT'"></app-logo>
        <div class="desktop-nav xs-hidden">
            <a
                class="nav-item"
                [routerLinkActive]="'active'"
                routerLink="/home"
            >
                <div class="nav-item-text">Home</div>
            </a>
            <a
                class="nav-item"
                [routerLinkActive]="'active'"
                routerLink="/compatibility"
            >
                <div class="nav-item-text">Compatibility List</div>
            </a>
            <a
                class="nav-item"
                [routerLinkActive]="'active'"
                routerLink="/about"
            >
                <div class="nav-item-text">About</div>
            </a>
        </div>
    </div>
    <div class="right">
        <a class="btn-sign-in" routerLink="/login">Sign In</a>
    </div>
</div>
<div class="mobile-nav sm-hidden" *ngIf="showMobileNav" [@vshrink] [@fade]>
    <a class="nav-item" [routerLinkActive]="'active'" routerLink="/home">
        <div class="nav-item-text">Home</div>
    </a>
    <a
        class="nav-item"
        [routerLinkActive]="'active'"
        routerLink="/compatibility"
    >
        <div class="nav-item-text">Compatibility List</div>
    </a>
    <a class="nav-item" [routerLinkActive]="'active'" routerLink="/about">
        <div class="nav-item-text">About</div>
    </a>
</div>
